import React, { useEffect, useState } from 'react'
import PersonalInfo from './PersonalInfo'
import AccountInfo from './AccountInfo'
import ChatHistory from './ChatHistory'
import baseUrl from '../../../config/baseUrl'

const ProfileSetting = () => {
  const userId: string | null = localStorage.getItem('userId')
  const token: string | null = localStorage.getItem('token')

  const [userDetails, setUserDetails] = useState<any>()

  useEffect(() => {
    document.title = 'Profile Setting - LawTech'
    const getUserData = async () => {
      try {
        const response: any = await fetch(
          `${baseUrl}/api/users/ProfileDetails/${userId as string} `,
          {
            method: 'GET',
            mode: 'cors',

            headers: {
              'Content-Type': 'application/json',
              authorization: token as string,
            },
          },
        )

        const data = await response.json()

        if (data.status === true) {
          setUserDetails(data?.personaldata)
        } else {
          console.error(' data  failed')
        }
      } catch (error) {
        console.error('An error occurred while submitting the form data', error)
      }
    }

    getUserData()
  }, [userId, token])

  return (
    <>
      <PersonalInfo userDetails={userDetails} userId={userId} token={token} />
      <AccountInfo userDetails={userDetails} />
      <ChatHistory />
    </>
  )
}

export default ProfileSetting
