import React, { useContext, useEffect, useState } from 'react'
import './index.css'
import baseUrl from '../../config/baseUrl'
import {
  Alert,
  Snackbar,
  AppBar,
  useMediaQuery,
  type Breakpoint,
  Container,
  Toolbar,
  Grid,
  Box,
  IconButton,
} from '@mui/material'
import type { AlertColor } from '@mui/material/Alert'

import FactCheckIcon from '@mui/icons-material/FactCheck'
import { AppLogoIcon } from '../Icons'
import NavLinks from './NavLinks'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import LawTechLogo from '../../assets/images/Law tech logo with patch.png'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import SContext from '../../contexts/SidebarContext'
import { Padding } from '@mui/icons-material'

interface Props {
  maxWidth: Breakpoint
  hideHeader?: boolean
}

const Header = ({ maxWidth, hideHeader }: Props) => {
  const token = localStorage.getItem('token')
  const theme = useTheme()
  const userId = sessionStorage.getItem('userid')
  const xsMatched = useMediaQuery(theme.breakpoints.down('xs'))
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [isVisible, setIsVisible] = useState(false);

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)


  useEffect(() => {
    // setIsVisible(true);
    let timer: any;
    setInterval(() => {
      setIsVisible(true);
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 15000);
    }, 900000);


    return () => { clearTimeout(timer) };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };



  // if (hideHeader) {
  //   return null
  // }

  const containerMaxWidth = smMatched || mdMatched ? false : maxWidth
  const handleOfferPlan = async (offerPlan: any) => {
    // // console.log('offerPlan', offerPlan)
    // try {
    //   const subscriptionResonse = await fetch(`${baseUrl}/api/order/create`, {
    //     method: 'POST',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       authorization: token ?? '',
    //     },
    //     body: JSON.stringify({
    //       userid: userId,
    //       amount: 4999,
    //       // amount: offerPlan?.Price,
    //       subscriptionid: offerPlan?.id,
    //     }),
    //   })

    //   const subscriptionResonseData = await subscriptionResonse.json()

    //   // console.log('subscriptionResonseData', subscriptionResonseData)
    //   if (subscriptionResonse.ok) {
    //     window.open(subscriptionResonseData.payment_link, '_blank')
    //   }
    // } catch (error) {
    //   setSnackbarMessage('Error in upgrade subscription');
    //   setSnackbarSeverity('error');
    //   setSnackbarOpen(true);
    // }

  }


  return (
    <AppBar style={{ zIndex: 10002 }} position='fixed' color='default' component='nav' >
      <Container maxWidth={containerMaxWidth} disableGutters={smMatched} sx={{ padding: smMatched || mdMatched ? '0.5rem 0rem !important' : '0rem 2.5rem', }}>
        <Toolbar style={{
          // border: '1px solid black',
          position: 'relative'
        }}>
          <Grid container>
            <Grid item xs={6}>
              <Box style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
              >
                {token === null && (
                  <Link to='/' style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}>
                    <img src={LawTechLogo} alt='' height='80px' />
                    {/* <AppLogoIcon sx={{ width: 91, height: 62 }} / > */}
                  </Link>
                )}

                {token !== null && (
                  <Link to='/newChat' style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}>
                    <img src={LawTechLogo} alt='' height='80px' />
                    {/* <AppLogoIcon sx={{ width: 91, height: 62 }} / > */}
                  </Link>
                )}

                {/* --------------------- Offer advertisement popup ------------------- */}
                {token !== null && isVisible && (
                  <Box className='popup-container' onClick={handleOfferPlan}>
                    <Box style={{
                      margin: '0 0 0 0',
                      fontSize: '1.5rem',
                      color: '#ff5722',
                    }}>
                      <div className="star-container">
                        <div className="star"></div>
                      </div>
                      <Box className='popup-content'>
                        <Box style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          boxSizing: 'border-box',
                          padding: 0,
                          margin: 0,
                          position: 'relative'
                        }}>
                          <h3 style={{ margin: 0, padding: 0, boxSizing: 'border-box', textAlign: 'center' }}>Special Offer!</h3>
                          <IconButton onClick={() => { setIsVisible(false) }} >
                            <CloseIcon sx={{ fontSize: 20, color: 'red' }} />
                          </IconButton>

                        </Box>
                        {/* <p style={{ margin: 0, padding: 0, boxSizing: 'border-box' }}>Check out this amazing deal. Don&apos;t miss out!</p> */}
                        <h4 style={{ margin: 0, padding: 0, boxSizing: 'border-box', textAlign: 'center' }}>3 months @4999 only</h4>
                        <p style={{ margin: 0, padding: 0, boxSizing: 'border-box', textAlign: 'right', color: '#000' }}>Offer valid for next 7 days only</p>
                      </Box>
                    </Box>
                  </Box>
                )}

                {/* {token !== null && (
                  <Link to='/verifier'>
                    <FactCheckIcon sx={{ zIndex: 10002, color: 'green', fontSize: 40 }} />
                  </Link>
                )} */}
              </Box>
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {/* Toggle button for small screens */}
              {smMatched ? (
                <IconButton onClick={toggleMenu} edge="end" color="inherit" sx={{ mr: 1 }}>
                  <MenuIcon sx={{ fontSize: 40, }} />
                </IconButton>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >

                  <NavLinks />
                </Box>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        {isMenuOpen && smMatched && (
          <Box sx={{ display: 'block', textAlign: 'center', p: 2 }}>
            <NavLinks />
          </Box>
        )}
      </Container>

    </AppBar >
  )
}

export default Header
