import React from 'react'
import baseUrl from '../../../../config/baseUrl'
import { toast } from 'react-toastify'
import { Box, Button, Stack, Typography } from '@mui/material'
import { CheckIcon } from '../../../../components/Icons'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const Plans = (Props) => {
  const { plan, subscribedPlanId, onClose } = Props

  // console.log('plan, subscribedPlanId, onClose', plan, subscribedPlanId, onClose)

  //   const email = sessionStorage.getItem('email')

  const userId = sessionStorage?.getItem('userId')

  // const subscriptionid = sessionStorage.getItem('subscriptionid')

  //   const phone = sessionStorage.getItem('phone')

  //   const firstName = sessionStorage.getItem('firstName')

  //   const lastName = sessionStorage.getItem('lastName')

  //   const fullName = `${firstName} ${lastName}`

  const token = sessionStorage?.getItem('token')

  // const isPaid = sessionStorage?.getItem('isPaid')

  // console.log('Plans, isPaid', isPaid)

  const handleUpgradePlan = async (plan) => {
    // console.log('plan', plan)
    try {
      const subscriptionResonse = await fetch(`${baseUrl}/api/order/create`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token,
        },
        body: JSON.stringify({
          userid: userId,
          amount: plan?.Price,
          subscriptionid: plan?.id,
        }),
      })

      const subscriptionResonseData = await subscriptionResonse.json()

      // console.log('subscriptionResonseData', subscriptionResonseData)
      if (subscriptionResonse.ok) {
        window.open(subscriptionResonseData.payment_link, '_blank')
      }
    } catch (error) {
      toast.error('Error in upgrade subscription : ', error?.message)
    }
    onClose()
  }

  return (
    <Box
      minWidth='calc(50% - 12px)'
      border='0.5px solid'
      borderColor='primary.main'
      borderRadius={1}
      boxShadow='0px 0px 12px rgba(0, 0, 0, 0.08)'
      bgcolor='common.white'
      p={1.5}
      spacing={2.5}
    >
      <Stack spacing={1}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='h6' variant='bold' color='textSecondary'>
            {plan?.PlanName}
          </Typography>
          {plan?.PlanName !== 'Basic' && (
            <Button
              onClick={() => {
                handleUpgradePlan(plan)
              }}
              variant='contained'
              sx={{ maxWidth: 146, height: 40, p: 0 }}
              disabled={
                subscribedPlanId === plan?.id ||
                plan?.PlanName === 'Free' ||
                (subscribedPlanId === 1 && plan?.PlanName === 'Top-up')
              }
            >
              {subscribedPlanId === plan?.id ? 'Subscribed' : 'Upgrade Plan'}
              {/* {subscribedPlanId === 1 && plan?.name === 'Top-up' ? 'Subscribed' : 'Upgrade Plan'} */}
            </Button>
          )}

          {/* <VisibilityBlackIcon sx={{ width: 20, height: 20, color: 'success.main' }} /> */}
        </Box>
        {/* <Button variant='contained' disabled sx={{ maxWidth: 146, height: 40, p: 0 }}>
          Current Plan
        </Button> */}
      </Stack>
      <Stack spacing={1}>
        <Box display='flex' alignItems='flex-start'>
          <CheckIcon sx={{ width: 20, height: 20, mr: 1.5, color: 'primary.main' }} />
          <Typography variant='subtitle1'>
            Price : <span>&#8377;</span> {plan?.Price?.toLocaleString('en-US')}
          </Typography>
        </Box>

        {/* <Box display='flex' alignItems='flex-start'>
          <CheckIcon sx={{ width: 20, height: 20, mr: 1.5, color: 'primary.main' }} />
          <Typography variant='subtitle1'>Features : {plan?.Features}</Typography>
        </Box> */}
        <Box display='flex' alignItems='flex-start'>
          <CheckIcon sx={{ width: 20, height: 20, mr: 1.5, color: 'primary.main' }} />
          <Typography variant='subtitle1'>
            Tokens : {plan?.Totaltoken?.toLocaleString('en-US')}
          </Typography>
        </Box>
        <Box display='flex' alignItems='flex-start'>
          <CheckIcon sx={{ width: 20, height: 20, mr: 1.5, color: 'primary.main' }} />
          <Typography variant='subtitle1'>
            Words (approx) : {Math.ceil((plan?.Totaltoken * 75) / 100).toLocaleString('en-US')}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          {subscribedPlanId === plan?.id && <CheckBoxIcon color='success' fontSize='large' />}
        </Box>
        {plan?.PlanName === 'Top-up' && (
          <Typography variant='body2' fontSize='12px' color='red' component='p' align='right'>
            Top-up plan is only for <strong>subscribed</strong> users
          </Typography>
        )}
        {plan?.PlanName === 'Basic' && (
          <Typography variant='body1' fontSize='12px' color='green' component='p' align='right'>
            <strong>Free Plan</strong>
          </Typography>
        )}
      </Stack>
    </Box>
  )
}

export default Plans
