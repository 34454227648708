import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor'
})

const UserIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 21' {...props}>
    <path
      d='M15.8337 18V16.3333C15.8337 15.4493 15.4825 14.6014 14.8573 13.9763C14.2322 13.3512 13.3844 13 12.5003 13H7.50033C6.61627 13 5.76842 13.3512 5.1433 13.9763C4.51818 14.6014 4.16699 15.4493 4.16699 16.3333V18'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0003 9.66667C11.8413 9.66667 13.3337 8.17428 13.3337 6.33333C13.3337 4.49238 11.8413 3 10.0003 3C8.15938 3 6.66699 4.49238 6.66699 6.33333C6.66699 8.17428 8.15938 9.66667 10.0003 9.66667Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default UserIcon
