import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AuthContext } from './contexts'
import AppRoutes from './routes'
import theme from './theme'
import { ToastContainer } from 'react-toastify'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { SidebarProvider } from './contexts/SidebarContext'

const App = () => {
  return (

    <ThemeProvider theme={theme}>
      <AuthContext>
        <CssBaseline />
        <ToastContainer position="top-right" style={{
          zIndex: '10000000'
        }} />
        <SidebarProvider>
          <AppRoutes />
        </SidebarProvider>

      </AuthContext>
    </ThemeProvider>

  )
}
export default App
