import * as React from 'react'
import { Box, Typography } from '@mui/material'
import ShareChat from './ShareChat'

const SharechatIndex = () => {
  return (
    <ShareChat />
    // <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7.5, mt: 12 }}>
    // </Box>
  )
}

export default SharechatIndex
