import React, { type ChangeEvent, useState, useEffect } from 'react'
import {
  Avatar,
  Button,
  IconButton,
  InputBase,
  type InputBaseProps,
  Stack,
  Typography,
  styled,
  alpha,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

// import { useSidebarContext } from '../../contexts'
import Stepper from '../ChatBoat/MainContent/Messages/Actions/Stepper'

interface Props {
    question: any
    index: any
    questionId: any
  
  
}

const StyledInputBase = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  borderWidth: 0.5,
  flexGrow: 1,
  height: 41,
  borderColor: alpha(theme.palette.common.black, 0.2),
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1.5),
  },
}))

const Question = ({  question, questionId, index }: Props) => {
 

  

  

  return (
    <Stack spacing={1}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' p={2} spacing={3.5}>
        <Stack direction='row' alignItems='center' flexGrow={1} spacing={2}>
          <Avatar variant='square' sx={{ bgcolor: '#587DBD' }}>
            RT
          </Avatar>
          
            <Typography variant='subtitle2' color='textSecondary'>
              {question}
            </Typography>
        
        </Stack>
        {/* <Stepper /> */}
        
      </Stack>
      
    </Stack>
  )
}

export default Question
