import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
  width: 20,
  height: 20,
})

const CircleTrashIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 16 16' {...props}>
    <g clipPath='url(#clip0_778_1012)'>
      <path
        d='M8.00016 14.6666C11.6821 14.6666 14.6668 11.6819 14.6668 7.99998C14.6668 4.31808 11.6821 1.33331 8.00016 1.33331C4.31826 1.33331 1.3335 4.31808 1.3335 7.99998C1.3335 11.6819 4.31826 14.6666 8.00016 14.6666Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 6L6 10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 6L10 10' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_778_1012'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </StyledSvgIcon>
)

export default CircleTrashIcon
