import React, { useState, type ReactNode, type SetStateAction } from 'react'
import {
  Modal,
  Box,
  Button,
  Typography,
  FormControl,
  FormLabel,
  Stack,
  Select,
  Divider,
  MenuItem,
  styled,
  type FormLabelProps,
  type SelectChangeEvent,
  Paper,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import SubCategory from './SubCategory'
import { useSidebarContext } from '../../../../../contexts'

import {
  acts,
  affidavits,
  deedApplications,
  districtAndSessionsCourtApplications,
  arbitration,
  drtApplications,
  familyCourtApplications,
  highCourtApplications,
  writTPettitions,
  jmfcApplications,
  notices,
  judgements,
  formats,
  biologicalDiversity,
  climateChangeAndPollution,
  importantArticles,
  economicOffences,
  civilProcedure,
  familyLaw,
  divorce,
  marriage,
  adoption,
  companiesBankruptcyAndInsolvency,
  contract,
  bankingAndFinance,
  companiesLaw,
  industrialRelation,
  labourAndEmploymentLaws,
  roadSafety,
  sexualOffensesAndHarrashmentAct,
  murder,
  fraudAndTheft,
  criminalProcedure,
  incomeTax,
  indirectTax,
  caseBased,
  domesticLegislationForInternationalLawAndConvention,
  patentTrademarkAndGI,
} from './data.js'
// import { SubCategories } from './data'

const StyledFormLabel = styled(FormLabel)<FormLabelProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  marginBottom: theme.spacing(1.5),
}))

const Categories = () => {
  const [open, setOpen] = useState(false);

  const [showCategory1, setShowCategory1] = useState(false)
  const [showCategory2, setShowCategory2] = useState(false)
  const [showCategory3, setShowCategory3] = useState(false)
  // const [optionsForSubCategories, setOptionsForSubCategories] = useState([])
  const [selectedCategory1, setSelectedCategory1] = useState('')
  const [selectedCategory2, setSelectedCategory2] = useState('')
  const [selectedCategory3, setSelectedCategory3] = useState('')
  const [hints, setHints] = useState<string[]>([]);
  const [category1, setCategory1] = useState<Array<{ value: string; label: string }>>([
    { value: 'acts', label: 'Acts' },
    { value: 'judgements', label: 'Judgements' },
    { value: 'formats', label: 'Formats / Notices' },
    { value: 'environmentalLaws', label: 'Environmental Laws' },
    { value: 'indianConstitution', label: 'Indian Constitution' },
    { value: 'civil', label: 'Civil' },
    { value: 'corporateLaw', label: 'Corporate Law' },
    { value: 'criminal', label: 'Criminal' },
    { value: 'taxation', label: 'Taxation' },
    { value: 'conditional', label: 'Conditional' },
    { value: 'internationalLaws', label: 'International Laws' },
    { value: 'intellectualProperty', label: 'Intellectual Property' },
  ])
  const [category2, setCategory2] = useState<Array<{ value: string; label: string }>>([])
  const [category3, setCategory3] = useState<Array<{ value: string; label: string }>>([])

  const { setMessage, setInputMessage } = useSidebarContext()

  const navigate = useNavigate()

  const handleOpen = () => { setOpen(true) };
  const handleClose = () => { setOpen(false) };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',  // Centers the modal
    width: '50vw',
    height: '60vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll'
  };

  const handleCategory1 = (e: SelectChangeEvent<string>, child: ReactNode) => {
    const { value } = e.target
    setSelectedCategory2(' ')
    setSelectedCategory1(value)

    if (value === 'acts') {
      setHints(acts as SetStateAction<string[]>);
      setShowCategory2(false)
      handleOpen()

    } else if (value === 'judgements') {
      setHints(judgements as SetStateAction<string[]>);
      setShowCategory2(false)
      handleOpen()

    } else if (value === 'formats') {
      const subCatagories = [
        { value: 'affidavits', label: 'Affadavits' },
        { value: 'deedApplications', label: 'Deed Applications' },
        { value: 'arbitration', label: 'Arbitration' },
        { value: 'districtAndSessionsCourtApplications', label: 'District and Sessions Court Applications' },
        { value: 'drtApplications', label: 'DRT Applications' },
        { value: 'familyCourtApplications', label: 'Family Court Applications' },
        { value: 'highCourtApplications', label: 'High Court Applications' },
        { value: 'writTPettitions', label: 'WRIT Pettitions' },
        { value: 'jmfcApplications', label: 'JMFC Applications' },
        { value: 'notices', label: 'Notices' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)

    } else if (value === 'environmentalLaws') {
      const subCatagories = [
        { value: 'biologicalDiversity', label: 'Biological Diversity' },
        { value: 'climateChangeAndPollution', label: 'Climate Change and Pollution' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'indianConstitution') {
      const subCatagories = [{ value: 'importantArticles', label: 'Important Articles' }]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'civil') {
      const subCatagories = [
        { value: 'economicOffences', label: 'Economic Offences' },
        { value: 'civilProcedure', label: 'Civil Procedure' },
        { value: 'familyLaw', label: 'Family Law' },
        { value: 'divorce', label: 'Divorce' },
        { value: 'marriage', label: 'Marriage' },
        { value: 'adoption', label: 'Adoption' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'corporateLaw') {
      const subCatagories = [
        {
          value: 'companiesBankruptcyAndInsolvency',
          label: 'Companies, Bankruptcy and Insolvency',
        },
        { value: 'contract', label: 'Contract' },
        { value: 'bankingAndFinance', label: 'Banking and Finance' },
        { value: 'companiesLaw', label: 'Companies Law' },
        { value: 'industrialRelation', label: 'Industrial Relation' },
        { value: 'labourAndEmploymentLaws', label: 'Labour and Employment Laws' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'criminal') {
      const subCatagories = [
        {
          value: 'roadSafety',
          label: 'Road Safety',
        },
        {
          value: 'sexualOffensesAndHarrashmentAct',
          label: 'Sexual Offenses and  Harassments act',
        },
        { value: 'murder', label: 'Murder' },
        { value: 'fraudAndTheft', label: 'Fraud and Theft' },
        { value: 'criminalProcedure', label: 'Criminal Procedure' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'taxation') {
      const subCatagories = [
        { id: 1, value: 'incomeTax', label: 'Income Tax' },
        { id: 2, value: 'indirectTax', label: 'Indirect Tax' },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'conditional') {
      const subCatagories = [
        {
          id: 1,
          value: 'caseBased',
          label: 'Case Based',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'internationalLaws') {
      const subCatagories = [
        {
          id: 1,
          value: 'domesticLegislationForInternationalLawAndConvention',
          label: 'Domestic Legislation for International Law and Convention',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else if (value === 'intellectualProperty') {
      const subCatagories = [
        {
          id: 1,
          value: 'patentTrademarkAndGI',
          label: 'Patent Trademark and GI',
        },
      ]
      setCategory2([...subCatagories])
      setShowCategory2(true)
    } else {
      toast.warning('Select proper input')
    }
  }

  const handleCategory2 = (e: SelectChangeEvent<string>, child: ReactNode) => {
    const { value } = e.target
    setSelectedCategory2(value)
    // console.log('value', value)
    if (value === 'affidavits') {
      setHints(affidavits as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'deedApplications') {
      setHints(deedApplications as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'arbitration') {
      setHints(arbitration as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'districtAndSessionsCourtApplications') {
      setHints(districtAndSessionsCourtApplications as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'drtApplications') {
      setHints(drtApplications as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'familyCourtApplications') {
      setHints(familyCourtApplications as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'highCourtApplications') {
      setHints(highCourtApplications as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'writTPettitions') {
      setHints(writTPettitions as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'jmfcApplications') {
      setHints(jmfcApplications as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'notices') {
      setHints(notices as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'biologicalDiversity') {
      setHints(biologicalDiversity as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'climateChangeAndPollution') {
      setHints(climateChangeAndPollution as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'importantArticles') {
      setHints(importantArticles as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'economicOffences') {
      setHints(economicOffences as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'civilProcedure') {
      setHints(civilProcedure as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'familyLaw') {
      setHints(familyLaw as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'divorce') {
      setHints(divorce as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'marriage') {
      setHints(marriage as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'adoption') {
      setHints(adoption as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'companiesBankruptcyAndInsolvency') {
      setHints(companiesBankruptcyAndInsolvency as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'contract') {
      setHints(contract as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'bankingAndFinance') {
      setHints(bankingAndFinance as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'companiesLaw') {
      setHints(companiesLaw as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'industrialRelation') {
      setHints(industrialRelation as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'labourAndEmploymentLaws') {
      setHints(labourAndEmploymentLaws as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'roadSafety') {
      setHints(roadSafety as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'sexualOffensesAndHarrashmentact') {
      setHints(sexualOffensesAndHarrashmentAct as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'murder') {
      setHints(murder as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'fraudAndTheft') {
      setHints(fraudAndTheft as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'criminalProcedure') {
      setHints(criminalProcedure as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'incomeTax') {
      setHints(incomeTax as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'indirectTax') {
      setHints(indirectTax as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'caseBased') {
      setHints(caseBased as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'domesticLegislationForInternationalLawAndConvention') {
      setHints(domesticLegislationForInternationalLawAndConvention as SetStateAction<string[]>);
      handleOpen()
    } else if (value === 'patentTrademarkAndGI') {
      setHints(patentTrademarkAndGI as SetStateAction<string[]>);
      handleOpen()
    } else {
      toast.warning('Select proper input')
    }
  }

  const handleCategory3 = (e: SelectChangeEvent<string>, child: ReactNode) => {
    const { value } = e.target
    console.log(value)
  }

  const handleActClick = (label: string) => {
    console.log('handleActClick label', label)
    setInputMessage(label)
    setMessage('')
    // navigate(`/NewChatQuestion/${label}`)
    setTimeout(() => {
      handleClose()
    }, 300);
  }

  return (
    <>
      <Stack spacing={2.5} paddingLeft='1rem'>
        <ToastContainer position='top-right' style={{
          zIndex: '10000000'
        }} />
        <FormControl>
          <StyledFormLabel focused={false}>Acts / Judgements / Formats</StyledFormLabel>
          <Select
            onChange={handleCategory1}
            value={selectedCategory1}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value=' ' selected>
              <em>Select</em>
            </MenuItem>

            {category1?.map((category) => {
              return (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <Divider />

        {showCategory2 && (
          <FormControl>
            <StyledFormLabel focused={false}>Sub-Categories</StyledFormLabel>
            <Select
              onChange={handleCategory2}
              value={selectedCategory2}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value=' ' selected>
                <em>Select</em>
              </MenuItem>
              {category2?.map((category) => {
                return (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      </Stack>

      {/* ------------------------- this section will be display in the middle screen --------------------------- */}
      <div>
        {/* <Button variant='contained' onClick={handleOpen}>
          Open Modal
        </Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-title'
          aria-describedby='modal-description'
        >
          <Box sx={modalStyle}>
            <Box style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>

              <Typography id='modal-title' variant='h6' component='h2'>
                Prompt Hints
              </Typography>

              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            {hints?.map((item: any, index: number) => {
              return (
                <Box
                  key={index}
                  style={{
                    margin: 0,
                    padding: 0,
                    boxSizing: 'border-box',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <h3 style={{
                    color: 'magenta', margin: 0,
                    padding: 0, marginRight: '5px'
                  }}>&#x2022;</h3>
                  <Paper
                    sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                    onClick={() => {
                      handleActClick(item)
                    }}
                  >
                    {item}
                  </Paper>
                </Box>
              )
            })}
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default Categories
