import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const StopCircleIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fill='white'
      d='M15 9H9V15H15V9Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default StopCircleIcon
