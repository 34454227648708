import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, Typography, Box, Button, Stack, Divider } from '@mui/material'
import type { AlertColor } from '@mui/material/Alert'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type InferType } from 'yup'

import { editChatTitleSchema } from '../../../lib/validation'
import { EditIcon } from '../../Icons'
import TextField from '../TextField'
import { useSidebarContext } from '../../../contexts'

interface Props {
  open: boolean
  onClose: () => void
  historyId: any
  title: any
}

type FormData = InferType<typeof editChatTitleSchema>

const EditChatTitleDialog = ({ open, onClose, historyId, title }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(editChatTitleSchema),
    defaultValues: {
      title: '',
    },
  })

  useEffect(() => {
    if (open) {
      setValue('title', title)
    }
  }, [open, title, setValue])

  const { editHistoryTItleAPi } = useSidebarContext()

  const onSubmit = (data: FormData) => {
    editHistoryTItleAPi(data.title, historyId)
    onClose()
    reset({ title: '' })
  }

  return (
    <>
      <Dialog open={open} maxWidth='md' fullWidth>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: 4,
          }}
        >
          <Stack spacing={3} width={1}>
            <Stack spacing={2} width={1}>
              <Box display='flex' alignItems='center' gap={2} width={1}>
                <Box display='flex' alignItems='center' color='text.secondary'>
                  <EditIcon />
                </Box>
                <Typography variant='body2' fontWeight={600} color='textSecondary'>
                  Edit Chat Title
                </Typography>
              </Box>
              <Divider component='div' sx={{ borderColor: 'background.border' }} />
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <Controller
                  name='title'
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <TextField
                      name={name}
                      onChange={onChange}
                      value={value}
                      error={errors.title?.message}
                      label='Title'
                      placeholder='Enter Your Title'
                    />
                  )}
                />

                <Stack direction='row' justifyContent='flex-end' spacing={2} width={1}>
                  <Button
                    variant='outlined'
                    sx={{ p: 0, maxWidth: 114, height: 44 }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button variant='contained' type='submit' sx={{ p: 0, maxWidth: 96, height: 44 }}>
                    Update
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EditChatTitleDialog
