import React, { useState } from 'react'
import { Box, Button, Grid, Stack, Typography, alpha, useTheme, Dialog, DialogContent } from '@mui/material'
import { HistroyIcon } from '../../../components/Icons'
import { useSidebarContext } from '../../../contexts'
import baseUrl from '../../../config/baseUrl'
import { toast } from 'react-toastify'

const ChatHistory = () => {
  const { palette } = useTheme()
  const userId = localStorage.getItem('userId')

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const { clearAllHistory } = useSidebarContext()

  const [shareLinksArray, setShareLinksArray] = useState<any>([])

  const getShareLinks = async () => {
    try {
      const getShareLinksResponse = await fetch(
        `${baseUrl}/api/sharechat/getallsharechat/${userId as string}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      const getShareLinksResponseData = await getShareLinksResponse.json()

      if (getShareLinksResponseData.status === true) {
        setShareLinksArray(getShareLinksResponseData.sharechatdata)
      }

      if (getShareLinksResponseData.status === false) {
        setShareLinksArray([])
      }
    } catch (error: any) {
      // console.log('Error in getting shareLink : ', error.message)
    }
  }

  const deleteAllChatLinkes = async () => {
    try {
      const deleteAllChatLinkesResponse = await fetch(
        `${baseUrl}/api/sharechat/deleteallsharechat/${userId as string}`,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      const deleteAllChatLinkesResponseData = await deleteAllChatLinkesResponse.json()

      if (deleteAllChatLinkesResponseData.status === true) {
        getShareLinks()
      }
    } catch (error: any) {
      // console.log('Delete all chatlinks : ', error.message)
    }
  }
  return (
    <Stack spacing={2}>
      <Box
        display='flex'
        alignItems='center'
        bgcolor={alpha(palette.primary.main, 0.1)}
        borderRadius={1}
        height={33}
        px={1.5}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          mr={1.5}
          color='text.secondary'
        >
          <HistroyIcon sx={{ width: 20, height: 20 }} />
        </Box>
        <Typography variant='subtitle2' color='text.secondary'>
          Chat History
        </Typography>
      </Box>
      <Grid container px={1.5}>
        <Grid item xs={12} md={7}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p='10px 0px 10px 20px'
            borderRadius={1}
          >
            <Typography variant='subtitle1' color='textSecondary'>
              Clear all Chats
            </Typography>
            <Button
              variant='outlined'
              sx={{ fontSize: 14, maxWidth: 75, p: 0, height: 37 }}
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
            >
              Clear
            </Button>
          </Box>
          {openDeleteDialog && (
            <Dialog open={openDeleteDialog} fullWidth PaperProps={{ sx: { maxWidth: 456 } }}>
              <DialogContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  p: '36px 48px',
                }}
              >
                <Stack spacing={3.5}>
                  <Typography variant='subtitle2' align='center' color='textSecondary'>
                    Are you sure, you want to clear all chats ?
                  </Typography>
                  <Stack direction='row' justifyContent='center' spacing={2} width={1}>
                    <Button variant='outlined' sx={{ p: 0, maxWidth: 102, height: 44 }} onClick={() => { setOpenDeleteDialog(false); }}>
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      sx={{ p: 0, maxWidth: 102, height: 44 }}
                      onClick={() => {
                        clearAllHistory();
                        deleteAllChatLinkes();
                        setOpenDeleteDialog(false);
                      }}
                    >
                      Confirm
                    </Button>
                  </Stack>
                </Stack>
              </DialogContent>
            </Dialog>
          )}

        </Grid>
      </Grid>
    </Stack>
  )
}

export default ChatHistory
