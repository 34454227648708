import React, { useEffect } from 'react'
import { Dialog, DialogContent, Typography, IconButton, Button } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'
import { CloseIcon } from '../../Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import baseUrl from '../../../config/baseUrl'
import { ToastContainer, toast } from 'react-toastify'

const PaymentSuccessfulDialog = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const orderId = searchParams.get('order_id')

  const navigate = useNavigate()

  const getOrderDetails = async (orderNumber: any) => {
    try {
      const getOrderDetailsResponse = await fetch(
        `${baseUrl}/api/order/getCallBack?order_id=${orderNumber as string}`,
      )

      const getOrderDetailsResponseData = await getOrderDetailsResponse.json()

      if (getOrderDetailsResponse.ok) {
        if (getOrderDetailsResponseData?.order?.order_status === 'PAID') {
          sessionStorage.setItem('isPaid', 'true')

          sessionStorage.setItem('orderDate', getOrderDetailsResponseData?.order?.updatedAt)
        }
      }
    } catch (error: any) {
      // console.log('Error in getOrderDetailsResponseData :', error.message)
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    document.title = 'Payment successful - LawTech'
  }, [])


  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId)
    }
  }, [orderId])

  const handleClose = () => {
    // navigate('/NewChat')

    // window.location.replace(`${baseUrl}/NewChat`)
    window.close()
    if (window.opener && !window.opener.closed) {
      window.opener.close()
    }
  }

  return (
    <Dialog PaperProps={{ sx: { maxWidth: 407 } }} open={true}>
      <ToastContainer />
      <IconButton
        disableRipple
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          color: 'text.secondary',
          p: 0,
          width: 20,
          height: 20,
        }}
      >
        {/* <CloseIcon /> */}
      </IconButton>
      <DialogContent
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 3, p: 6 }}
      >
        <Typography align='center'>Payment Successful</Typography>

        <Typography align='center'>Payment: {orderId}</Typography>

        <Button variant='contained' color='primary' onClick={handleClose}>
          Continue
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default PaymentSuccessfulDialog
