import React, { useEffect } from 'react'
// import baseUrl from '../../config/baseUrl.ts'
// import InputMessage from '../ChatBoat/MainContent/SendMessage/InputMessage.tsx'
import { Box, Paper, Stack, Typography, styled } from '@mui/material'
import {
  acts,
  judgements,
  formats,
  biologicalDiversity,
  climateChangeAndPollution,
  importantArticles,
  economicOffences,
  civilProcedure,
  familyLaw,
  divorce,
  marriage,
  adoption,
  companiesBankruptcyAndInsolvency,
  contract,
  bankingAndFinance,
  companiesLaw,
  industrialRelation,
  labourAndEmploymentLaws,
  roadSafety,
  sexualOffensesAndHarrashmentAct,
  murder,
  fraudAndTheft,
  criminalProcedure,
  incomeTax,
  indirectTax,
  caseBased,
  domesticLegislationForInternationalLawAndConvention,
  patentTrademarkAndGI,
} from './data.js' // Judgements, Formats
// import { SendIcon } from '../../components/Icons/'
// import ResponseButton from '../ChatBoat/MainContent/SendMessage/ResponseButton.tsx'
// import { useSidebarContext } from '../../contexts/index.js'
import { useSidebarContext } from '../../contexts/index'
// import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../ChatBoat/LeftSidebar/Actions/Sidebar'
import { DRAWER_WIDTH } from '../ChatBoat/constants'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: any
}>(({ theme, open }) => ({
  display: 'flex',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  padding: theme.spacing(2, 4, 2, 2),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.spacing(2),
  }),
}))

function ManyCategories() {
  const navigate = useNavigate()
  const category = useParams()
  console.log('ManyCategories category', category)


  // const [inputQuestion, setInputQuestion] = useState('')
  // const hasMessageValue = 0
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('userId')

  const { setMessage, setInputMessage, sidebarOpen, setSidebar } = useSidebarContext()

  useEffect(() => {
    document.title = 'Prompt hints - LawTech'
  }, [])

  // useEffect(() => {
  //   // userStatus()
  // }, [message])

  // const handleEncode = () => {
  //   const encodedQuery = encodeURIComponent(query);
  //   console.log('Encoded Query:', encodedQuery);
  // };

  const handleActClick = (label: string) => {
    console.log('handleActClick label', label)
    setInputMessage(label)
    setMessage('')
    // navigate(`/NewChatQuestion/${label}`)
    setTimeout(() => {
      navigate('/NewChat')
    }, 300);

    // setMessage(label)
    // setLastAskedQuestion(label)
    // setInputQuestion(label)
  }

  // const handleChange = (event) => {}

  // const handleSendClick = () => {
  //   setIsLoading(true) // setIsLoading(true)
  //   setInputQuestion('')
  //   userStatus()

  //   // setMessage('')
  // }

  // const userStatus = async () => {
  //   try {
  //     const response = await fetch(`${baseUrl}/api/admin/checkuserstatus/${userId}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         authorization: token,
  //       },
  //       body: JSON.stringify({
  //         Promptquery: message,
  //       }),
  //     })

  //     const responseData = await response.json()

  //     responseData?.status && handleSearchCount()
  //   } catch (error) {
  //     setIsLoading(false)
  //     console.log('Error in userStatus API :', error.message)
  //   }
  // }

  // const handleSearchCount = async () => {
  //   try {
  //     const handleSearchCountResponse = await fetch(`${baseUrl}/api/users/search`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         UserId: userId,
  //       }),
  //     })

  //     const handleSearchCountResponseData = await handleSearchCountResponse.json()

  //     if (handleSearchCountResponse.ok) {
  //       sendData()
  //     }

  //     if (!handleSearchCountResponse.ok) {
  //       toast.error(handleSearchCountResponseData.message)
  //        setIsLoading(false)
  //     }
  //   } catch (error) {
  //     console.log('Error in searchount api :', error.message)
  //   }
  // }

  return (
    <div style={{ left: '335px', marginTop: '5.5rem' }}>
      <Main open={sidebarOpen}>
        {!sidebarOpen && <Sidebar />}
        <Stack flexGrow={1} alignItems='center' position='relative'>
          <Stack flexGrow={1} spacing={2} width={1} maxWidth={1000}>
            <Stack flexGrow={1} spacing={2}>
              <Stack
                padding='1rem'
                height='75dvh'
                minWidth='100%'
                position='relative'
                sx={{ mt: 12 }}
              >
                <Typography
                  variant='subtitle1'
                  style={{ fontWeight: 'bold', marginBottom: '10px' }}
                >
                  Prompt Hints *
                </Typography>
                {/* <div style={{ position: 'fixed', bottom: '10px' }}> */}
                <Box overflow='scroll' minWidth='100%'>
                  {category?.category === 'acts' &&
                    acts?.map((act, index) => {
                      return (
                        <Paper
                          key={index}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(act?.value)
                          }}
                        >
                          {act?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'judgements' &&
                    judgements?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'formats' &&
                    formats?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'biologicalDiversity' &&
                    biologicalDiversity?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'climateChangeAndPollution' &&
                    climateChangeAndPollution?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'importantArticles' &&
                    importantArticles?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'economicOffences' &&
                    economicOffences?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'civilProcedure' &&
                    civilProcedure?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'familyLaw' &&
                    familyLaw?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'divorce' &&
                    divorce?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'marriage' &&
                    marriage?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'adoption' &&
                    adoption?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'companiesBankruptcyAndInsolvency' &&
                    companiesBankruptcyAndInsolvency?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'contract' &&
                    contract?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'bankingAndFinance' &&
                    bankingAndFinance?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'companiesLaw' &&
                    companiesLaw?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'industrialRelation' &&
                    industrialRelation?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'labourAndEmploymentLaws' &&
                    labourAndEmploymentLaws?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'roadSafety' &&
                    roadSafety?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'sexualOffensesAndHarrashmentAct' &&
                    sexualOffensesAndHarrashmentAct?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'murder' &&
                    murder?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'fraudAndTheft' &&
                    fraudAndTheft?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'criminalProcedure' &&
                    criminalProcedure?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'incomeTax' &&
                    incomeTax?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'indirectTax' &&
                    indirectTax?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'caseBased' &&
                    caseBased?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'domesticLegislationForInternationalLawAndConvention' &&
                    domesticLegislationForInternationalLawAndConvention?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}

                  {category?.category === 'patentTrademarkAndGI' &&
                    patentTrademarkAndGI?.map((item) => {
                      return (
                        <Paper
                          key={item?.id}
                          sx={{ my: '0.3rem', p: '0.3rem 0.4rem', cursor: 'pointer' }}
                          onClick={() => {
                            handleActClick(item?.value)
                          }}
                        >
                          {item?.label}
                        </Paper>
                      )
                    })}
                </Box>

                {/* <Stack
        spacing={1}
        position='sticky'
        bottom={0}
        sx={{
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#F8F8F8 54.73%)',
        }}
      >
        <Stack direction='row' alignItems='flex-end' flexGrow={1} spacing={2}>
          <Stack flexGrow={1}>
            <ResponseButton />
            <InputMessage inputQuestion={inputQuestion} onChange={handleChange} />
          </Stack>
          <Button
            variant='contained'
            disabled={hasMessageValue}
            sx={{ minWidth: 50, width: 50, height: 50, p: 0 }}
            onClick={() => {
              handleSendClick() // Optionally, call handleSendClick as well
            }}
          >
            <SendIcon />
          </Button>
        </Stack>
        <Typography variant='link' fontWeight='regular'>
          <Typography variant='link' color='error.main'>
            *
          </Typography>{' '}
          This platform may produce inaccurate information, that doesn’t represent Law Tech views
        </Typography>
      </Stack> */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Main>{' '}
    </div>
  )
}

export default ManyCategories
