import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.8512 6.45129C18.9367 6.36587 19.0046 6.26446 19.0509 6.15282C19.0972 6.04118 19.1211 5.92152 19.1212 5.80065C19.1212 5.67979 19.0975 5.56009 19.0513 5.4484C19.0051 5.33671 18.9374 5.2352 18.852 5.14969C18.7666 5.06417 18.6652 4.99631 18.5535 4.94999C18.4419 4.90367 18.3222 4.87979 18.2014 4.87972C18.0805 4.87964 17.9608 4.90337 17.8491 4.94956C17.7374 4.99574 17.6359 5.06347 17.5504 5.14889L12 10.6993L6.45119 5.14889C6.27849 4.97618 6.04424 4.87915 5.79999 4.87915C5.55575 4.87915 5.3215 4.97618 5.14879 5.14889C4.97609 5.3216 4.87906 5.55584 4.87906 5.80009C4.87906 6.04433 4.97609 6.27858 5.14879 6.45129L10.6992 12.0001L5.14879 17.5489C5.06328 17.6344 4.99544 17.7359 4.94916 17.8477C4.90288 17.9594 4.87906 18.0791 4.87906 18.2001C4.87906 18.321 4.90288 18.4408 4.94916 18.5525C4.99544 18.6642 5.06328 18.7658 5.14879 18.8513C5.3215 19.024 5.55575 19.121 5.79999 19.121C5.92093 19.121 6.04069 19.0972 6.15242 19.0509C6.26415 19.0046 6.36568 18.9368 6.45119 18.8513L12 13.3009L17.5504 18.8513C17.7231 19.0238 17.9573 19.1206 18.2014 19.1205C18.4455 19.1203 18.6795 19.0232 18.852 18.8505C19.0245 18.6778 19.1213 18.4436 19.1212 18.1995C19.121 17.9554 19.0239 17.7214 18.8512 17.5489L13.3008 12.0001L18.8512 6.45129Z'
      fill='#233A48'
    />
  </SvgIcon>
)

export default CloseIcon
