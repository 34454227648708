import React, { useEffect, useState } from 'react'
import baseUrl from '../../../config/baseUrl'
import { Link as RouteLink } from 'react-router-dom'
import {
  Alert,
  Snackbar,

  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Button,
  Stack,
  Divider,
  Link,
} from '@mui/material'
import type { AlertColor } from '@mui/material/Alert'

import { CheckIcon, UserIcon, VisibilityBlackIcon } from '../../Icons'

import { ToastContainer, toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'

import ReactSpeedometer from 'react-d3-speedometer'
import Plans from '../../../pages/ChatBoat/LeftSidebar/History/Plans'
import { number, string } from 'yup'
import { setServers } from 'dns'

interface Props {
  open: boolean
  close: () => void
}

const UpgradeSubscriptionDialog = ({ open, close }: Props) => {
  const [allPlans, setAllPlans] = useState([])

  const [subscribedPlanId, setSubscribedPlanId] = useState(null)
  const [showPaymentGateway, setShowPaymentGateway] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<any>(sessionStorage.getItem('phone'))
  const [planUsageDialogOpen, setPlanUsageDialogOpen] = useState(false)
  const [planUsageData, setPlanUsageData] = useState(Object)
  const [tokenConsumed, setTokenConsumed] = useState(null)
  const [tokenRemaining, setTokenRemaining] = useState(null)
  const [wordsRemaining, setWordsRemaining] = useState<string | undefined>('')

  const email = sessionStorage.getItem('email')

  const userId = localStorage.getItem('userId')

  const subscriptionid = sessionStorage.getItem('subscriptionid')

  const phone = sessionStorage.getItem('phone')

  const firstName = sessionStorage.getItem('firstName')

  const lastName = sessionStorage.getItem('lastName')

  const fullName = `${firstName as string} ${lastName as string}`

  const token = localStorage.getItem('token')

  const isPaid = sessionStorage.getItem('isPaid')

  const [successfullyDialog, setSuccessfullyDialog] = useState<any>(false)

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)


  useEffect(() => {
    if (open) {
      getAllPlans()
      getSubscribedPlan()
      getProfileDetails()
    }
  }, [open])

  // useEffect(() => {
  //   const sortedAllPlans = []
  //   if (allPlans.length > 0) {
  //     sortedAllPlans = allPlans.sort((a, b) => a.id - b.id)
  //   }
  // }, [allPlans])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };


  const getAllPlans = async () => {
    const response = await fetch(`${baseUrl}/api/admin/plan/allplans`)
    const data = await response.json()
    // console.log('data.data', data?.data)
    const sortedData = data?.data.sort((a: any, b: any) => a.id - b.id)
    // console.log('sortedData', sortedData)
    setAllPlans(sortedData)
  }

  const getSubscribedPlan = async () => {
    try {
      if (userId !== null) {
        const res = await fetch(`${baseUrl}/api/users/usercurrentplan/${userId}`)
        const data = await res.json()

        if (!res.ok) {
          toast.warning(data.description)
          return
        }

        if (data) {
          if (data?.status) {
            setSubscribedPlanId(data.data)
          }
        }
      }
    } catch (error: any) {
      toast(error.message)
    }
  }

  const getProfileDetails = async () => {
    try {
      const response: any = await fetch(
        `${baseUrl}/api/users/ProfileDetails/${userId as string} `,
        {
          method: 'GET',
          mode: 'cors',

          headers: {
            'Content-Type': 'application/json',
            authorization: token as string,
          },
        },
      )

      const data = await response.json()

      if (data.status === true) {
        if (!data?.personaldata?.PhoneNumber) {
          setSnackbarMessage('Please update your mobile number in Settings, Profile Settings...');
          setSnackbarSeverity('info');
          setSnackbarOpen(true)
          close();
          return
        }
        console.log('profile data', data)
        setTokenRemaining(data?.personaldata?.Tokens?.toLocaleString('en-US'))
        const wr = Math.ceil((data?.personaldata?.Tokens * 75) / 100).toLocaleString('en-US')
        setWordsRemaining(wr)
      } else {
        console.error(' data  failed')
      }
    } catch (error) {
      console.error('An error occurred while submitting the form data', error)
    }
  }

  // ------------------------------- getConsumption ---------------
  const getConsumption = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/users/barometerapi/${userId ?? ''}`)
      const data = await res.json()

      if (!res.ok) {
        toast.warning(data.description)
        return
      }

      if (data) {
        setPlanUsageData(data)
      }
    } catch (error: any) {
      // console.log(error)
      toast.error(error)
    }
  }

  const openPlanUsageDialog = () => {
    getConsumption()
    setPlanUsageDialogOpen(true)
  }

  const closePlanUsageDialog = () => {
    setPlanUsageDialogOpen(false)
  }

  const redirect = () => {
    const paymentWindow = window.open(
      'https://pay.openai.com/c/pay/cs_live_a1toPHUdFzMQRDm5rL63ISMOQsAjpxvCywePZI9cJMLcuEIujqqvJkw2PD#fid1d2BpamRhQ2prcSc%2FJ0xrcWB3JykndnBndmZ3bHVxbGprUGtsdHBga2B2dkBrZGdpYGEnP3F3cGApJ2R1bE5gfCc%2FJ3VuWmlsc2BaMDRNSndWckYzbTRrfUJqTDZpUURiV29cU3d%2FMWFQNmNTSmRnfEZmTlc2dWdAT2JwRlNEaXRGfWF9RlBzaldtNF1ScldkZlNsanNQNm5JTnN1bm9tMkx0blI1NWxdVHZvajZrJyknY3dqaFZgd3Ngdyc%2FcXdwYCknaWR8anBxUXx1YCc%2FJ3Zsa2JpYFpscWBoJyknYGtkZ2lgVWlkZmBtamlhYHd2Jz9xd3BgeCUl',
      '_blank',
    )
    // window.paymentWindow = paymentWindow
  }

  const handleClose = () => {
    setSuccessfullyDialog(false)
  }

  const checkTokenConsumption = () => {
    // fetch(`${baseUrl}/api/admin/eachusertoken`)
  }

  useEffect(() => {
    // if (phoneNumber === 'null') {
    //   toast.error('Please enter a contact number', {
    //     position: toast.POSITION.TOP_CENTER,
    //   })
    // }
  }, [open, phoneNumber])

  const handleUpgradePlan = async () => {
    try {
      const subscriptionResonse = await fetch(`${baseUrl}/api/order/create`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          userid: userId,
          amount: 20,
          subscriptionid: subscriptionid,
        }),
      })

      const subscriptionResonseData = await subscriptionResonse.json()

      if (subscriptionResonse.ok) {
        window.open(subscriptionResonseData.payment_link, '_blank')
      }
    } catch (error: any) {
      toast.error('Error in upgrade subscription : ', error.message)
    }
  }

  const renderPlanUsageDialog = () => (
    <Dialog open={planUsageDialogOpen} fullWidth onClose={closePlanUsageDialog} sx={{ zIndex: '10003' }}>
      <DialogContent
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography variant='h5'>Hello! Your Usage Consumption in % (Percentage).</Typography>
        <IconButton onClick={closePlanUsageDialog}>
          <CloseIcon color='warning' />
        </IconButton>
      </DialogContent>

      <div
        style={{
          marginTop: '20px',
          padding: '20px',
          width: '100%',
          height: '325px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          zIndex: 5,
        }}
      >
        <ReactSpeedometer
          maxValue={100}
          value={planUsageData?.data?.toFixed(2)}
          // value={Number(planUsageData?.data) + 200}
          ringWidth={10}
          customSegmentStops={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          needleColor='#F54E4E'
          needleHeightRatio={0.8}
          needleTransitionDuration={2000}
        />
        {/* <h6>{planUsageData?.data}%</h6> */}
      </div>
    </Dialog>
  )

  return (
    <>
      <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: 646 } }} sx={{ zIndex: '10002' }}>
        <ToastContainer />
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: 4,
          }}
        >
          <Stack spacing={3} width={1} position='relative'>
            <Stack spacing={2} width={1}>
              <Box
                // onClick={() => {
                //   redirect()
                // }}
                display='flex'
                alignItems='center'
                gap={2}
                width={1}
              >
                <Box display='flex' alignItems='center' color='text.secondary'>
                  <UserIcon sx={{ width: 20, height: 20 }} />
                </Box>
                <Typography
                  variant='body2'
                  fontWeight={600}
                  color='textSecondary'
                  sx={{ bgColor: 'white' }}
                >
                  Upgrade Subscription
                </Typography>
              </Box>
              <Divider component='div' sx={{ borderColor: 'background.border' }} />
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Token remaining : {tokenRemaining}</Typography>
                <Typography>Words remaining : {wordsRemaining}</Typography>
              </Box>
            </Stack>

            <Stack
              style={{ margin: '5px auto' }}
              spacing={3}
              direction='row'
              width={1}
              overflow='auto'
            >
              <Stack style={{ margin: '30px auto' }} spacing={3} direction='row'>
                {allPlans.map((plan, index) => {
                  return (
                    <Plans
                      key={index}
                      plan={plan}
                      onClose={close}
                      subscribedPlanId={subscribedPlanId}
                    />
                  )
                })}
                <Divider component='div' sx={{ borderColor: 'background.border' }} />
              </Stack>
            </Stack>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems='center'
              justifyContent='space-between'
              textAlign='center'
              spacing={3}
              width={1}
            >
              <Link
                component={RouteLink}
                to='/faq/need-help-with-a-billing-issue'
                underline='none'
                sx={{ '&:hover': { textDecoration: 'none', color: 'inherit' } }}
              >
                Need help with a billing issue
              </Link>

              <div style={{ cursor: 'pointer' }}>
                <Link
                  underline='none'
                  onClick={() => {
                    openPlanUsageDialog()
                  }}
                  sx={{ '&:hover': { textDecoration: 'none', color: 'inherit' } }}
                >
                  Plan Usage
                </Link>
              </div>

              <Button variant='outlined' sx={{ p: 0, maxWidth: 114, height: 44 }} onClick={close}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
        {renderPlanUsageDialog()}
      </Dialog>
      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            elevation={6}
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}

    </>
  )
}

export default UpgradeSubscriptionDialog
