import React, { useState, useEffect } from 'react'
import baseUrl from '../../../config/baseUrl'
import { Paper, Grid, Typography, Box } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
const TransactionHistory = () => {
  const userId = localStorage.getItem('userId')
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    getTransactions()
  }, [])

  const getTransactions = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/admin/userpaymenthistory/${userId}`)
      const data = await res.json()

      if (!res.ok) {
        // console.log(data.description)
        toast.error(data?.description)
        return
      }

      // console.log(data)
      setTransactions(data.ordersData)
    } catch (error) {
      // console.log(error)
      toast.error(error?.message)
    }
  }

  return (
    <>
      <ToastContainer />
      <div style={{ overflowX: 'auto' }}>
        <Box sx={{ minWidth: '650px' }}>
          <Paper sx={{ width: '100%' }}>
            <Grid container sx={{ padding: '8px 0' }}>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                  Sr.No
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                  Plan Name
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' align='center'>
                  Amount Paid
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' align='center'>
                  Payment Id
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: 'bold' }} variant='subtitle1' align='center'>
                  Subscription Date
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>

      {transactions &&
        transactions?.map((transaction, index) => {
          return (
            <Paper sx={{ width: '100%' }} key={index}>
              <Grid container sx={{ padding: 1 }} spacing={1}>
                <Grid item xs={1}>
                  <Typography variant='body1' align='center'>
                    {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='body1'>{transaction?.planName}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='body1' align='right'>
                    {transaction?.paymentAmount &&
                      Number(transaction?.paymentAmount).toLocaleString('en-US')}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='body1' align='right'>
                    {transaction?.paymentId}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='body1' align='right'>
                    {transaction?.paymentCreatedDate &&
                      new Date(transaction?.paymentCreatedDate).toLocaleDateString(
                        'en-US',
                        'M/d/yyyy',
                      )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )
        })}
      {transactions.length === 0 && <Typography variant='body1' mt='8px'>No Transactions yet</Typography>}
    </>
  )
}

export default TransactionHistory
