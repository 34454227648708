import React from 'react'
import { useMediaQuery, Box, Container, Divider, Grid, Typography } from '@mui/material'
import { ChevronRightIcon, GridIcon, KeyIcon, SearchIcon } from '../../../components/Icons'
import { useTheme } from '@mui/material/styles'

const HowItWorksSection = () => {
  const theme = useTheme()
  const xsMatched = useMediaQuery(theme.breakpoints.down('xs'))
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container maxWidth={smMatched ? false : 'xl'} sx={{ bgcolor: '#FFF' }}>
      <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant={mdMatched ? 'h3' : 'h1'}>How It Works</Typography>
      </Box>
      <Grid container spacing={3.8}>
        <Grid item xs={12} sm={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
              flexGrow: 1,
              px: 3,
              py: 2,
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 80,
                  height: 80,
                  borderRadius: '100%',
                  bgcolor: 'primary.main',
                }}
              >
                <KeyIcon
                  sx={{
                    color: 'common.white',
                    fill: 'none',
                    stroke: 'currentcolor',
                  }}
                />
              </Box>
              <Divider
                component='div'
                sx={{
                  border: 'none',
                  height: 2,
                  bgcolor: 'rgba(251, 207, 36, 0.4)',
                  opacity: 0.4,
                  position: 'absolute',
                  width: 'calc(100% / 2)',
                  right: '-32px',
                }}
              />
              <ChevronRightIcon
                sx={{
                  position: 'absolute',
                  right: '-48px',
                  width: 16,
                  height: 16,
                  bgcolor: 'common.white',
                  color: 'primary.main',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1,
                px: 4,
              }}
            >
              <Typography variant='h6' align='center'>
                Login/ Sign Up
              </Typography>
              <Typography align='center' color='common.black'>
                Turn your idea from concept to MVP
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
              flexGrow: 1,
              px: 3,
              py: 2,
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 1,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 80,
                  height: 80,
                  borderRadius: '100%',
                  bgcolor: 'primary.main',
                }}
              >
                <GridIcon
                  sx={{
                    color: 'common.white',
                    fill: 'none',
                    stroke: 'currentcolor',
                  }}
                />
              </Box>
              <Divider
                component='div'
                sx={{
                  border: 'none',
                  height: 2,
                  bgcolor: 'rgba(251, 207, 36, 0.4)',
                  opacity: 0.4,
                  position: 'absolute',
                  width: 'calc(100% / 2)',
                  left: '-30px',
                }}
              />
              <Divider
                component='div'
                sx={{
                  border: 'none',
                  height: 2,
                  bgcolor: 'rgba(251, 207, 36, 0.4)',
                  opacity: 0.4,
                  position: 'absolute',
                  width: 'calc(100% / 2)',
                  right: '-32px',
                }}
              />
              <ChevronRightIcon
                sx={{
                  position: 'absolute',
                  right: '-48px',
                  width: 16,
                  height: 16,
                  bgcolor: 'common.white',
                  color: 'primary.main',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography variant='h6' align='center'>
                Select Jurisdiction Category
              </Typography>
              <Typography align='center' color='common.black'>
                Select details specific to your search
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
              flexGrow: 1,
              px: 3,
              py: 2,
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 80,
                  height: 80,
                  borderRadius: '100%',
                  bgcolor: 'primary.main',
                }}
              >
                <SearchIcon
                  sx={{
                    color: 'common.white',
                    fill: 'none',
                    stroke: 'currentcolor',
                  }}
                />
              </Box>
              <Divider
                component='div'
                sx={{
                  border: 'none',
                  height: 2,
                  bgcolor: 'rgba(251, 207, 36, 0.4)',
                  opacity: 0.4,
                  position: 'absolute',
                  width: 'calc(100% / 2)',
                  left: '-30px',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1,
                px: 5,
              }}
            >
              <Typography variant='h6' align='center'>
                Start Exploring
              </Typography>
              <Typography align='center' color='common.black'>
                Launching the application to the market
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default HowItWorksSection
