import React from 'react'
import baseUrl from '../../../../config/baseUrl'
import { Box, Button, Tooltip } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { useSidebarContext } from '../../../../contexts'
import { RefreshIcon, StopCircleIcon } from '../../../../components/Icons'

interface Props {
  generatingResponse?: boolean
}

const ResponseButton = ({ generatingResponse }: Props) => {
  const {
    sendData,
    message,
    isLoading,
    setIsLoading,
    lastAskedQuestion,
    setMessage,
    setIsResponseComplete,
  } = useSidebarContext()
  const token = localStorage.getItem('token')

  const isPaid = sessionStorage.getItem('isPaid')

  const userId = localStorage.getItem('userId')

  const userStatus = async () => {
    // if (message === '') {
    if (lastAskedQuestion === '') {
      // setIsResponseComplete(false)
      toast('Need input from the user')
      return
    }
    try {
      const response = await fetch(`${baseUrl}/api/admin/checkuserstatus/${userId as string}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          // Promptquery: message,
          Promptquery: lastAskedQuestion,
        }),
      })

      const responseData = await response.json()

      responseData?.status && handleSearchCount()
    } catch (error: any) {
      // console.log('Error in userStatus API :', error.message)
      toast.error(error)
    }
  }

  const handleSearchCount = async () => {
    try {
      const handleSearchCountResponse = await fetch(`${baseUrl}/api/users/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserId: userId,
        }),
      })

      const handleSearchCountResponseData = await handleSearchCountResponse.json()

      if (handleSearchCountResponse.ok) {
        // sendData()
      }

      if (!handleSearchCountResponse.ok) {
        toast.error(handleSearchCountResponseData.message)
        setIsLoading(false)
      }
    } catch (error: any) {
      // console.log('Error in search count api :', error.message)
      toast.error(error)
    }
  }

  return (
    <Box display='flex' justifyContent='center' mb={0.5}>
      <ToastContainer />
      <Button
        variant='contained'
        sx={({ spacing }) => ({
          width: 'auto',
          maxWidth: 1,
          height: spacing(6),
          p: spacing(1.5, 2.5),
        })}
        onClick={userStatus}
      // startIcon={generatingResponse ? <StopCircleIcon /> : <RefreshIcon />}
      >
        {generatingResponse ? <StopCircleIcon /> : <RefreshIcon />}
      </Button>
    </Box>
  )
}

export default ResponseButton
