import React from 'react'
import { Stack, Box, alpha, useTheme, Typography, Grid } from '@mui/material'
import { UserIcon } from '../../../components/Icons'
import TextField from '../../../components/shared/TextField'
import ContactNumber from './ContactNumber'

interface Props {
  userDetails: any,
  token: any,
  userId: any,
}

const PersonalInfo = ({ userDetails, token, userId }: Props) => {
  const { palette } = useTheme()


  const { FirstName, LastName, PhoneNumber } = userDetails || {}





  return (
    <Stack spacing={2}>
      <Box
        display='flex'
        alignItems='center'
        bgcolor={alpha(palette.primary.main, 0.1)}
        borderRadius={1}
        height={33}
        px={1.5}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          mr={1.5}
          color='text.secondary'
        >
          <UserIcon sx={{ width: 20, height: 20 }} />
        </Box>
        <Typography variant='subtitle2' color='text.secondary'>
          Personal Info.
        </Typography>
      </Box>
      <Grid container px={1.5}>
        <Grid item md={7}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
              <TextField label='First Name' name='firstName' value={FirstName} disabled />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label='Last Name' name='lastName' value={LastName} disabled />
            </Grid>
            <ContactNumber phoneNumber={PhoneNumber} token={token} userId={userId} />
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default PersonalInfo
