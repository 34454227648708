import React, { useState } from 'react'
import { Stack } from '@mui/material'
import NewChatButton from './NewChatButton'
import SearchInput from './Search'
import Sidebar from './Sidebar'

const Actions = () => {
  // const [searchOpen, setSearchOpen] = useState<boolean>(false)
  return (
    <>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        {/* <NewChatButton open={searchOpen} /> */}
        {/* <Stack direction='row' spacing={1.5}>
          <SearchInput open={searchOpen} onClick={setSearchOpen} />
          <Sidebar title='Hide Sidebar' placement='right' />
        </Stack> */}
      </Stack>
    </>)
}

export default Actions
