import React from 'react'
import { Tooltip as MuiToolTip, tooltipClasses, type TooltipProps, styled } from '@mui/material'

interface Props {
  title: string
  placement?: TooltipProps['placement']
  children: React.ReactElement
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiToolTip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.spacing(1.5),
    borderRadius: 5,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1, 2)
  },
}))

const Tooltip = ({ title, placement, children }: Props) => {
  return (
    <StyledTooltip title={title} placement={placement} disableInteractive>
      {children}
    </StyledTooltip>
  )
}

export default Tooltip
