import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor'
})

const DatabaseIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 20' {...props}>
    <path
      d='M10 6.66675C14.1421 6.66675 17.5 5.54746 17.5 4.16675C17.5 2.78604 14.1421 1.66675 10 1.66675C5.85786 1.66675 2.5 2.78604 2.5 4.16675C2.5 5.54746 5.85786 6.66675 10 6.66675Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.5 10C17.5 11.3833 14.1667 12.5 10 12.5C5.83333 12.5 2.5 11.3833 2.5 10'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.5 4.16675V15.8334C2.5 17.2167 5.83333 18.3334 10 18.3334C14.1667 18.3334 17.5 17.2167 17.5 15.8334V4.16675'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default DatabaseIcon
