import * as React from 'react'
import { Box } from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ContactSection from './ContactUs'
import WhyChooseUsSection from './WhyChooseUs'
import OurClientsSection from './OurClients'
import HowItWorksSection from './HowItWorks'
import MainSection from './MainSection'

const HomeIndex = () => {
  console.log('home Index')

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7.5, mt: 12 }}>
      <Header maxWidth='false' />
      <MainSection />
      <HowItWorksSection />
      {/* <OurClientsSection /> */}
      <WhyChooseUsSection />
      <ContactSection />
      <Footer />
    </Box>
  )
}

export default HomeIndex
