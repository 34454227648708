import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const BankNoteIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 37 37" {...props}>
    <path
      d='M27.8335 11.5H7.8335C6.45278 11.5 5.3335 12.6193 5.3335 14V24C5.3335 25.3807 6.45278 26.5 7.8335 26.5H27.8335C29.2142 26.5 30.3335 25.3807 30.3335 24V14C30.3335 12.6193 29.2142 11.5 27.8335 11.5Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.8335 21.5C19.2142 21.5 20.3335 20.3807 20.3335 19C20.3335 17.6193 19.2142 16.5 17.8335 16.5C16.4528 16.5 15.3335 17.6193 15.3335 19C15.3335 20.3807 16.4528 21.5 17.8335 21.5Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3335 18.5H10.346M25.321 18.5H25.3335'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default BankNoteIcon
