import React from 'react'
import { Button, Link, Stack, Box, Typography, styled, useMediaQuery } from '@mui/material'
import { NavLink as NavLinkBase, type NavLinkProps } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'
import { useAuthContext } from '../../contexts'
import { useTheme } from '@mui/material/styles'


const StyledNavLink = styled(NavLinkBase)<NavLinkProps>(({ theme }) => ({
  '&.active': {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}))

const NavLinks = () => {
  const { isAuthenticated } = useAuthContext()
  const token = localStorage.getItem('token')
  const theme = useTheme()

  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))


  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: smMatched ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
      gap={3.5}
    >
      {token == null && (
        <Link component={StyledNavLink} to='/' underline='none' color='text.contrastText'>
          <Typography variant='body2'>Home</Typography>
        </Link>
      )}

      {token !== null && (
        <Link component={StyledNavLink} to='/newChat' underline='none' color='text.contrastText'>
          <Typography variant='body2'>Home</Typography>
        </Link>
      )}

      {token !== null && (
        <Link component={StyledNavLink} to='/verifier' underline='none' color='text.contrastText'>
          <Typography variant='body2'>Verifier</Typography>
        </Link>
      )}

      {token !== null && (
        <Link component={StyledNavLink} to='/editor' underline='none' color='text.contrastText'>
          <Typography variant='body2'>Editor</Typography>
        </Link>
      )}

      <Link component={StyledNavLink} to='/contact-us' underline='none' color='text.contrastText'>
        <Typography variant='body2'>Contact Us</Typography>
      </Link>
      {/* {!isAuthenticated && (
        <Button
          component={NavLinkBase}
          to='/auth/login'
          variant='contained'
          sx={{ width: 120, py: 1.5, px: 2.3 }}
        >
          Login
        </Button>
      )} */}
      {token == null && (
        <Button
          component={NavLinkBase}
          to='/auth/login'
          variant='contained'
          sx={{ width: 120, py: 1.5, px: 2.3 }}
        >
          Login
        </Button>
      )}
      {token && <ProfileMenu />}
    </Box>
  )
}

export default NavLinks
