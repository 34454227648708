import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor'
})

const SettingIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 21' {...props}>
    <path
      d='M16.667 6.33325H9.16699'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.667 14.6667H4.16699'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.167 17.1667C15.5477 17.1667 16.667 16.0475 16.667 14.6667C16.667 13.286 15.5477 12.1667 14.167 12.1667C12.7863 12.1667 11.667 13.286 11.667 14.6667C11.667 16.0475 12.7863 17.1667 14.167 17.1667Z'
      stroke='#333333'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.83301 8.83325C7.21372 8.83325 8.33301 7.71396 8.33301 6.33325C8.33301 4.95254 7.21372 3.83325 5.83301 3.83325C4.4523 3.83325 3.33301 4.95254 3.33301 6.33325C3.33301 7.71396 4.4523 8.83325 5.83301 8.83325Z'
      stroke='#333333'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default SettingIcon
