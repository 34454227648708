import React from 'react'
import { Dialog, DialogContent, Typography, Box, IconButton } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'
import { CloseIcon } from '../../Icons'

interface Props {
  open: boolean
  onClose: () => void
}

const ThankYouDialog = ({ open, onClose }: Props) => {
  return (
    <Dialog open={open} PaperProps={{ sx: { maxWidth: 490 } }}>
      <IconButton
        disableRipple
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          color: 'text.secondary',
          p: 0,
          width: 24,
          height: 24,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 3,
          p: 6,
        }}
      >
        <Player
          autoplay
          loop
          src='https://lottie.host/acd0c537-3249-4082-929f-a8344534f267/HdbWgWS92o.json'
          style={{ height: 140, width: 140 }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2.5,
          }}
        >
          <Typography variant='h5'>Thank You!</Typography>
          <Typography align='center'>
            Your query has been sent successfully, Our representative will get back to you soon.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ThankYouDialog
