import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
  width: 20,
  height: 20
})

const FilterIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 24 24' {...props}>
    <polygon
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      points='22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3'
    />
  </StyledSvgIcon>
)

export default FilterIcon
