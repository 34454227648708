import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import NewChatButton from '../Actions/NewChatButton'
// import { FilteredProvider } from '../../../../contexts'
import Categories from './Categories'
import ApplyButton from './ApplyButton'
import SearchInput from '../Actions/Search'
import Sidebar from '../Actions/Sidebar'
const Filters = () => {
  const category = localStorage.getItem('category')
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [newCategory, setNewCategory] = useState(category)

  return (
    // <FilteredProvider>
    <Stack>
      <Box display='flex' alignItems='center' justifyContent='space-between' >
        <NewChatButton />
        <Sidebar />
      </Box>

      <Stack spacing={2.5} flexGrow={1} justifyContent='space-between' marginBottom='100px'>
        <Categories />
      </Stack>
    </Stack>
    // </FilteredProvider>
  )
}

export default Filters
