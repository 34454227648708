import React, { useState } from 'react'
import { Avatar, Paper, Stack } from '@mui/material'
import Message from './SharedMessage'
import CopyButton from '../ChatBoat/MainContent/Messages/Actions/CopyButton'
import Stepper from '../ChatBoat/MainContent/Messages/Actions/Stepper'
import { useSidebarContext } from '../../contexts'

interface Props {
  answer: string
  historyId: string
}

const Messages = ({ answer, historyId }: Props) => {
  const { activeMessageContext, setActiveMessageContext, messages } = useSidebarContext()
  const message = messages[activeMessageContext]

  return (
    <Paper elevation={0} sx={{ p: 2, flexGrow: 1 }}>
      <Stack spacing={2} direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Avatar variant='square'>LT</Avatar>
        <Message message={answer} />
        <Stack direction='row' spacing={2}>
          {/* <Stepper /> */}
          <CopyButton message={answer} />
        </Stack>
      </Stack>
    </Paper>
  )
}

export default Messages
