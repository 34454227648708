import React, { useEffect, useState } from 'react'
import baseUrl from '../../../config/baseUrl'
import { Link as RouteLink } from 'react-router-dom'
import {
  Checkbox,
  Box,
  Button,
  Typography,
  Link,
  Grid,
  InputAdornment,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string, type InferType } from 'yup'
import TextField from '../../../components/shared/TextField'
// import { clearConfigCache } from 'prettier'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { registerSchema } from '../../../lib/validation'
import { Visibility, VisibilityOff } from '@mui/icons-material'

interface Props {
  tcAccepted: boolean
  setTcAccepted: any
  setStep: any
  setBackotp: any
  setRegistrationDetails: any
  registrationDetail: any
}

type FormData = InferType<typeof registerSchema>

const RegisterForm = ({
  tcAccepted,
  setTcAccepted,
  setStep,
  setBackotp,
  setRegistrationDetails,
  registrationDetail,
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(registerSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      contactNo: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  })

  const values = {
    firstName: '',
    lastName: '',
    contactNo: '',
    email: '',
    password: '',
    confirmPassword: '',
  }

  const [Registration, setRegistration] = useState<any>(values)
  const [termsCheckbox, setTermsCheckbox] = useState(false)
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
  const theme = useTheme()
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    document.title = 'Register - LawTech'
    // localStorage.clear()
    // console.log('register form')
    sessionStorage.clear()
    // console.log('firstName', localStorage.getItem('firstName'))
    // console.log('lastName', localStorage.getItem('lastName'))
    // console.log('contactNo', localStorage.getItem('contactNo'))
    // console.log('email', localStorage.getItem('email'))
    // console.log('password', localStorage.getItem('password'))
    // console.log('confirmPassword', localStorage.getItem('confirmPassword'))
  }, [])

  const handleChange = (e: any) => {
    const { name, value } = e.target
    if (name === 'firstName') {
      localStorage.setItem('firstName', value)
    }
    if (name === 'lastName') {
      localStorage.setItem('lastName', value)
    }
    if (name === 'contactNo') {
      localStorage.setItem('contactNo', value)
    }
    if (name === 'email') {
      localStorage.setItem('email', value)
    }
    if (name === 'password') {
      localStorage.setItem('password', value)
    }
    if (name === 'confirmPassword') {
      localStorage.setItem('confirmPassword', value)
    }

    setRegistration({
      ...Registration,
      [name]: value,
    })
  }

  const handlePasswordPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault() // Prevent the paste action
  }

  const dataSubmit = async () => {
    localStorage.setItem('storeEmail', Registration.email)
    sessionStorage.setItem('storeEmail', Registration.email)

    try {
      // Make an API call here using the formData

      if (Registration.confirmPassword !== Registration.password) {
        // Passwords don't match, show an error message or handle it as needed

        // You can also display an error message to the user
        toast.error('Passwords do not match')
        return // Don't proceed with the API call
      }

      if (Registration.confirmPassword === Registration.password) {
        const response = await fetch(`${baseUrl}/api/auths/register1`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            // 'Origin': 'http://65.20.84.105',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            FirstName: Registration.firstName,
            LastName: Registration.lastName,

            PhoneNumber: Registration.contactNo,
            Password: Registration.confirmPassword,
            Email: Registration.email,
          }),
        })

        const data = await response.json()

        data && console.log('response data', data)

        if (data.status === true) {
          localStorage.setItem('userRegisteredId', data?.user?.id)
          localStorage.setItem('userId', data?.user?.id)
          localStorage.setItem('email', data?.user?.Email)

          sessionStorage.setItem('userRegisteredId', data?.user?.id)
          sessionStorage.setItem('userId', data?.user?.id)
          sessionStorage.setItem('email', data?.user?.Email)
          // Handle successful response
          setRegistrationDetails(Registration)
          setStep(1)

          setBackotp(data?.user?.Otp)
        } else {
          // Handle error response
          console.error('Form data submission failed')
          toast.error(data.message)
          // console.log(data.message)
        }
      } else {
        // Passwords don't match, show an error message or handle it as needed
        console.error('Data Submission Failed')
        // You can also display an error message to the user
        toast.error('Data submisson failed')
      }
    } catch (error) {
      console.error('An error occurred while submitting the form data', error)
    }
  }

  const handleCopy = (e: any) => {
    e.preventDefault()

    toast.warning('Copy not allowed')
    return false
  }

  const handlePaste = (e: any) => {
    e.preventDefault()

    toast.warning('Paste not allowed')
    return false
  }

  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleTermsCheckbox = () => {
    setTcAccepted(!tcAccepted)
    setTermsCheckbox(!termsCheckbox)
  }

  return (
    <form onSubmit={handleSubmit(dataSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          gap: 3,
        }}
      >
        <Grid container spacing={2.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={10} sm={6}>
            <Controller
              name='firstName'
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextField
                  name={name}

                  onChange={(e) => {
                    onChange(e)
                    handleChange(e) // Call the separate handler
                  }}
                  value={value}
                  error={errors.firstName?.message}
                  label='First Name'
                  placeholder='Enter First Name'
                  isRequired
                />
              )}
            />
          </Grid>
          <Grid item xs={10} sm={6}>
            <Controller
              name='lastName'
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextField
                  name={name}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e) // Call the separate handler
                  }}
                  value={value}
                  error={errors.lastName?.message}
                  label='Last Name'
                  placeholder='Enter Last Name'
                  isRequired
                />
              )}
            />
          </Grid>
          <Grid item xs={10} sm={6}>
            <Controller
              name='contactNo'
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextField
                  type='number'
                  name={name}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e) // Call the separate handler
                  }}
                  value={value}
                  error={errors.contactNo?.message}
                  label='Contact No.'
                  placeholder='Enter Contact No.'
                  isRequired
                />
              )}
            />
          </Grid>
          <Grid item xs={10} sm={6}>
            <Controller
              name='email'
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextField
                  name={name}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e) // Call the separate handler
                  }}
                  onCopy={handleCopy}
                  onPaste={handlePaste}
                  value={value}
                  error={errors.email?.message}
                  label='Email'
                  placeholder='Enter Email'
                  isRequired
                />
              )}
            />
          </Grid>
          <Grid item xs={10} sm={6}>
            <Controller
              name='password'
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextField
                  // type='password'
                  type={showPassword ? 'text' : 'password'}
                  name={name}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e) // Call the separate handler
                  }}
                  onCopy={handleCopy}
                  onPaste={handlePaste}
                  value={value}
                  error={errors.password?.message}
                  label='Password'
                  placeholder='Enter Password'
                  isRequired
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={10} sm={6}>
            <Controller
              name='confirmPassword'
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextField
                  // type='password'
                  type={showConfirmPassword ? 'text' : 'password'}
                  name={name}
                  onChange={(e) => {
                    onChange(e)
                    handleChange(e) // Call the separate handler
                  }}
                  onCopy={handleCopy}
                  onPaste={handlePaste}
                  value={value}
                  error={errors.confirmPassword?.message}
                  label='Confirm Password'
                  placeholder='Enter Password'
                  isRequired
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowConfirmPassword}
                      // onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Box display='flex' alignItems='center' margin='0' sx={{ padding: { xs: '0 20px', sm: 0 } }}>
          <Checkbox {...label} value={termsCheckbox} onClick={handleTermsCheckbox} />
          <Typography>
            Accept
            <Link
              component={RouteLink}
              variant='subtitle2'
              to='/auth/privacy-terms'
              underline='hover'
              color='textSecondary'
              ml={0.5}
            >
              Terms & Conditions
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', sm: 'flex-start' },
            width: '100%',
            gap: 1,
          }}
        >
          <Button
            // onClick={() => {
            //   dataSubmit()
            // }}
            disabled={!termsCheckbox}
            type='submit'
            variant='contained'
          >
            Proceed
          </Button>
          <Typography variant='subtitle1'>
            Already a member?
            <Link
              component={RouteLink}
              variant='subtitle2'
              to='/auth/login'
              underline='hover'
              color='textSecondary'
              ml={0.5}
            >
              Login
            </Link>
          </Typography>
        </Box>
      </Box>
    </form>
  )
}

export default RegisterForm
