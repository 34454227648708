import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor'
})

const EmailIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 21' {...props}>
    <path
      d='M16.667 3.83325H3.33366C2.41318 3.83325 1.66699 4.57944 1.66699 5.49992V15.4999C1.66699 16.4204 2.41318 17.1666 3.33366 17.1666H16.667C17.5875 17.1666 18.3337 16.4204 18.3337 15.4999V5.49992C18.3337 4.57944 17.5875 3.83325 16.667 3.83325Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.3337 6.33325L10.8587 11.0833C10.6014 11.2444 10.3039 11.3299 10.0003 11.3299C9.69673 11.3299 9.39927 11.2444 9.14199 11.0833L1.66699 6.33325'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default EmailIcon
