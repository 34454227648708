import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
  width: 20,
  height: 20,
})

const CopyIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 20' {...props}>
    <g clipPath='url(#clip0_192_4520)'>
      <path
        d='M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.16699 12.5H3.33366C2.89163 12.5 2.46771 12.3244 2.15515 12.0119C1.84259 11.6993 1.66699 11.2754 1.66699 10.8334V3.33335C1.66699 2.89133 1.84259 2.4674 2.15515 2.15484C2.46771 1.84228 2.89163 1.66669 3.33366 1.66669H10.8337C11.2757 1.66669 11.6996 1.84228 12.0122 2.15484C12.3247 2.4674 12.5003 2.89133 12.5003 3.33335V4.16669'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_192_4520'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </StyledSvgIcon>
)

export default CopyIcon
