// // FOR LOCAL
// const baseUrl: string = 'http://65.20.82.105:9002'

// const baseUrl: string = 'http://localhost:9003'

// export const domain: string = 'http://localhost:3000'

// export const pythonUrl: string = 'http://65.20.82.105:9002'

// FOR TEST SERVER
// const baseUrl: string = 'http://65.20.82.105:9002'
// export const pythonUrl: string = 'http://65.20.82.105:9002'
// export const domain: string = 'http://65.20.82.105'

// FOR SERVER
const baseUrl: string = 'http://65.20.82.105:9002'
export const pythonUrl: string = 'http://65.20.82.105:9002'
export const domain: string = 'http://65.20.82.105:9002'

export default baseUrl

// user panel

// const baseUrl: string = 'http://13.232.139.226:9001'
