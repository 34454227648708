import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const ChevronRightIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 21' strokeWidth='1.5' {...props}>
    <path
      d='M7.5 15.5L12.5 10.5L7.5 5.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default ChevronRightIcon
