import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

interface ExtendedSvgIconProps extends SvgIconProps {
  strokeColor?: string
}

const StyledSvgIcon = styled(SvgIcon)<ExtendedSvgIconProps>(({ strokeColor }) => ({
  fill: 'rgba(255, 255, 255, 0.7)',
  stroke: strokeColor, // Corrected stroke color
  width: 20,
  height: 20,
  cursor: 'pointer', // Corrected cursor spelling
}))

const MicIcon = ({ strokeColor, ...props }: SvgIconProps & { strokeColor?: string }) => (
  <StyledSvgIcon viewBox='0 0 20 20' strokeColor={strokeColor} {...props}>
    <path
      d='M10 1.66663C9.33696 1.66663 8.70107 1.93002 8.23223 2.39886C7.76339 2.8677 7.5 3.50358 7.5 4.16663V9.99996C7.5 10.663 7.76339 11.2989 8.23223 11.7677C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7677C12.2366 11.2989 12.5 10.663 12.5 9.99996V4.16663C12.5 3.50358 12.2366 2.8677 11.7678 2.39886C11.2989 1.93002 10.663 1.66663 10 1.66663Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.8332 8.33337V10C15.8332 11.5471 15.2186 13.0309 14.1246 14.1248C13.0307 15.2188 11.5469 15.8334 9.99984 15.8334C8.45274 15.8334 6.96901 15.2188 5.87505 14.1248C4.78109 13.0309 4.1665 11.5471 4.1665 10V8.33337'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10 15.8334V18.3334' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </StyledSvgIcon>
)

export default MicIcon
