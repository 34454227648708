import React, { useContext, createContext, useState } from 'react'

interface AuthContextValue {
  isAuthenticated: boolean
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
}

interface Props {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined)

export const AuthProvider = ({ children }: Props) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false)
  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const authContext = useContext(AuthContext)
  if (authContext === undefined) {
    throw new Error('useAuthContext must be inside authenication')
  }
  return authContext
}

export default AuthProvider
