import React, { useState, type ChangeEvent, useEffect, useContext } from 'react'
import { Avatar, Box, Paper, Stack, Typography, styled, CircularProgress } from '@mui/material'
import { useSidebarContext } from '../../../contexts'
import SContext, { SidebarProvider } from '../../../contexts/SidebarContext'
import { DRAWER_WIDTH } from '../constants'
import Sidebar from '../LeftSidebar/Actions/Sidebar'
import ActiveFilters from './ActiveFilters'
import NewMessage from './NewMessage'
import SendMessage from './SendMessage'
import Messages from './Messages'

import Message from './Messages/Message'
import DisLikeButton from './Messages/Actions/DisLikeButton'
import LikeButton from './Messages/Actions/LikeButton'
import CopyButton from './Messages/Actions/CopyButton'
import { useParams } from 'react-router-dom'
// import { HistoryContext } from '../../../contexts/HistoryContext'
import Stepper from './Messages/Actions/Stepper'
import { toast } from 'react-toastify'
import baseUrl from '../../../config/baseUrl'
import Verifier from '../../NewChat/Verifier'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: any
}>(({ theme, open }) => ({
  display: 'flex',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  padding: theme.spacing(2, 4, 2, 2),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.spacing(2),
  }),
}))

const MainContent = () => {
  const {
    isLoading,
    setIsLoading,
    sidebarOpen,
    setSidebar,
    allHistory,
    aiStorage,
    setAiStorage,
    inputMessage,
    setInputMessage,
    answer,
    message,
    setAnswer,
    setThreadId,
    // setIsThreadIdDelete,
    // isThredIdDelete,

    threadId,
    isThredIdDelete,
    setIsThreadIdDelete,
  } = useSidebarContext()

  const { openVerifier, setOpenVerifier } = useContext(SContext)

  const token = localStorage.getItem('token')
  const category = localStorage.getItem('category')
  const param = useParams()
  const { id } = param

  useEffect(() => {
    document.title = 'Main content - LawTech'
  }, [])

  useEffect(() => {
    if (id !== '') {
      // setIsLoading(true)
      setThreadId((prevId: any) => (prevId = id))
    }
  }, [id])

  useEffect(() => {
    threadId !== '' && getUserHistory()
  }, [threadId])

  // For Pagination :

  const [mergedData, setMergedData] = useState<any>([])

  useEffect(() => {
    if (allHistory.length > 0) {
      const newArray = allHistory.map((obj: any) => {
        // console.log('obj inside allHistory', obj)
        if (obj.subSerach) {
          return {
            ...obj,
            subSerach: [obj, ...obj.subSerach], // Move the object into subSerach
          }
        } else {
          return obj
        }
      })

      setMergedData(newArray)
    }
  }, [allHistory])

  // Updated code for pagination 3

  const [subSearchActiveSteps, setSubSearchActiveSteps] = useState<any>(allHistory.map(() => 0))

  useEffect(() => {
    setSubSearchActiveSteps(allHistory.map(() => 0))
  }, [allHistory])


  const getUserHistory = async () => {
    try {
      const userHistoryRespo = await fetch(`${baseUrl}/api/users/gethistory/${id as string}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
      })

      const userHistoryData = await userHistoryRespo.json()
      // console.log('userHistoryData', userHistoryData)
      userHistoryData && setAiStorage(userHistoryData)
      // setIsLoading(false)
    } catch (error: any) {
      // setIsLoading(false)
      // console.log('This Error occur in fetching history ', error.message)
      toast.error(
        'This Error occur in fetching history, please try again later or check your network connection...',
      )
    }
  }

  const handleQuestionUpdate = (newQuestion: any) => {
    // Update the question in the state or perform any necessary actions
  }

  const handleSubSearchStepChange = (index: number, step: number) => {
    setSubSearchActiveSteps((prevActiveSteps: any) => {
      const newActiveSteps = [...prevActiveSteps]
      newActiveSteps[index] = step
      return newActiveSteps
    })
  }

  // Handle search count

  const userId = localStorage.getItem('userId')

  const isPaid = sessionStorage.getItem('isPaid')

  return (
    <div style={{ left: '335px', marginTop: '5.5rem' }}>
      {isLoading && (
        <div
          style={{
            // height: '100dvh',
            // width: '100dvw',
            zIndex: 1350,
            position: 'absolute',
            // backgroundColor: 'rgba(202, 92, 92, 0.5)',
          }}
        >
          {/* <h3 style={{ color: 'green', position: 'fixed', top: '45%', left: '45%' }}>
            Fetching data...
          </h3> */}

          {/* <CircularProgress
            disableShrink
            color='primary'
            thickness={4}
            style={{ position: 'fixed', top: '50%', left: '50%' }}
          /> */}
        </div>
      )}
      {/* {openVerifier && <Verifier anchorSide='left' openVerifier={true} />} */}
      <Main open={sidebarOpen}>
        {!sidebarOpen && <Sidebar />}
        <Stack flexGrow={1} alignItems='center' position='relative'>
          <Stack flexGrow={1} spacing={2} width={1} maxWidth={1000}>
            <Stack flexGrow={1} spacing={2}>
              {/* <ActiveFilters /> */}

              {mergedData?.length === 0 && (
                <NewMessage category={category} aiStorage={aiStorage} setAiStorage={setAiStorage} />
              )}

              {mergedData?.map((each: any, eachIndex: number) => {
                return (
                  <div key={each.id}>
                    <Stepper
                      subSearchArray={each.subSerach || []}
                      activeStep={subSearchActiveSteps[eachIndex]}
                      handleStepChange={(step: number) => {
                        handleSubSearchStepChange(eachIndex, step)
                      }}
                    />
                  </div>
                )
              })}
              {/* <MessageProvider>
              <Messages />
            </MessageProvider> */}
            </Stack>
            <p>
              The provided information outlines the required experience, skill sets, and
              responsibilities for a position related to designing and delivering UI solutions for
              network operational processes. Below is a breakdown of the qualifications and skills
              needed for this role.:
            </p>

            <SendMessage
              setInputMessage={setInputMessage}
              setAnswer={setAnswer}
              aiStorage={aiStorage}
              setAiStorage={setAiStorage}
            />
          </Stack>
        </Stack>
      </Main>{' '}
    </div>
  )
}

export default MainContent
