import React, { useState } from 'react'
import { Box, Button, Divider, Grid, Stack, Typography, alpha, useTheme } from '@mui/material'
import { SettingIcon } from '../../../components/Icons'
import ManageLinkDialog from '../../../components/shared/Dialogs/ManageLink'
import ExportDataDialog from '../../../components/shared/Dialogs/ExportData'

const OtherSettings = () => {
  const { palette } = useTheme()
  const [manageLinkDialog, setManageLinkDialog] = useState<boolean>(false)
  const [exportDataDialog, setExportDataDialog] = useState<boolean>(false)

  const toggleManageLinkDialog = () => {
    setManageLinkDialog(!manageLinkDialog)
  }

  const toggleExportDataDialog = () => {
    setExportDataDialog(!exportDataDialog)
  }

  return (
    <Stack spacing={2}>
      <Box
        display='flex'
        alignItems='center'
        bgcolor={alpha(palette.primary.main, 0.1)}
        borderRadius={1}
        height={33}
        px={1.5}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          mr={1.5}
          color='text.secondary'
        >
          <SettingIcon sx={{ width: 20, height: 20 }} />
        </Box>
        <Typography variant='subtitle2' color='text.secondary'>
          Other Settings
        </Typography>
      </Box>
      <Grid container px={1.5} gap={1.5}>
        <Grid item xs={12} md={7}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p='10px 0px 10px 20px'
            borderRadius={1}
          >
            <Typography variant='subtitle1' color='textSecondary'>
              Shared Links
            </Typography>
            <Button
              variant='outlined'
              sx={{ fontSize: 14, maxWidth: 95, p: 0, height: 37 }}
              onClick={toggleManageLinkDialog}
            >
              Manage
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={7}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            p='10px 0px 10px 20px'
            borderRadius={1}
          >
            <Typography variant='subtitle1' color='textSecondary'>
              Export Data
            </Typography>
            <Button
              variant='outlined'
              sx={{ fontSize: 14, maxWidth: 95, p: 0, height: 37 }}
              onClick={toggleExportDataDialog}
            >
              Export
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ManageLinkDialog open={manageLinkDialog} onClose={toggleManageLinkDialog} />
      <ExportDataDialog open={exportDataDialog} onClose={toggleExportDataDialog} />
    </Stack>
  )
}

export default OtherSettings
