import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import moment from 'moment'
import baseUrl from '../../../config/baseUrl'
import { ToastContainer, toast } from 'react-toastify'

const SubscriptionDetails = () => {
  const [date, setDate] = useState<any>(null)

  useEffect(() => {
    const localStorageDate = sessionStorage.getItem('ExpiryData')
    const transactionDate = moment().add(30, 'days').calendar(sessionStorage.getItem('orderDate'))

    setDate(transactionDate)
  }, [])

  const isPaid = sessionStorage.getItem('isPaid')
  const userId = localStorage.getItem('userId')

  const token = localStorage.getItem('token')
  const handleUpgradePlan = async () => {
    try {
      const subscriptionResonse = await fetch(`${baseUrl}/api/order/create`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          authorization: token as string,
        },
        body: JSON.stringify({
          userid: userId,
          amount: 20,
        }),
      })

      const subscriptionResonseData = await subscriptionResonse.json()

      if (subscriptionResonse.ok) {
        // window.location.href = subscriptionResonseData.payment_link
        window.open(subscriptionResonseData.payment_link, '_blank')
      }
    } catch (error: any) {
      // console.log('Error in upgrade subscription : ', error.message)
      toast.error(error)
    }
  }
  return (
    <></>
    // <Grid item md={7}>
    //   <Typography variant='body2' color='textSecondary'>
    //     Subscription Details
    //   </Typography>
    //   <Stack spacing={0.8} direction='row' alignItems='flex-start'>
    //     <Box display='flex' flexDirection='column' gap={1} py={1.3} width='78%'>
    //       <Typography variant='body1' color='common.gray'>
    //         Law Tech Premium
    //       </Typography>
    //       <Typography variant='body1' color='common.light'>
    //         Lorem ipsum dolor sit amet consectetur. Odio porttitor tellus justo nunc quis. Enim
    //         tellus mauris adipiscing cursus
    //       </Typography>
    //       <Typography variant='body1' color='common.gray'>
    //         Expires On :{' '}
    //         <Typography component='span' variant='body2'>
    //           {date === 'null'
    //             ? 'Please Subscribe to primium'
    //             : moment(date).format('DD MMMM YYYY')}
    //         </Typography>
    //       </Typography>
    //     </Box>
    //     <Box display='flex' alignItems='center' justifyContent='flex-end' py={2} width='20%'>
    //       <Button
    //         variant='outlined'
    //         sx={{ maxWidth: 83, height: 37, p: 0 }}
    //         disabled={isPaid === 'true'}
    //         onClick={() => {
    //           handleUpgradePlan()
    //         }}
    //       >
    //         Renew
    //       </Button>
    //     </Box>
    //   </Stack>
    // </Grid>
  )
}

export default SubscriptionDetails
