import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const ScaleOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 40 40' {...props}>
    <rect width='40' height='40' rx='4' fill='#FF655F' fillOpacity='0.2' />
    <path
      d='M24 24L27 16L30 24C29.13 24.65 28.08 25 27 25C25.92 25 24.87 24.65 24 24Z'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 24L13 16L16 24C15.13 24.65 14.08 25 13 25C11.92 25 10.87 24.65 10 24Z'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15 29H25'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20 11V29'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 15H13C15 15 18 14 20 13C22 14 25 15 27 15H29'
      stroke='#233A48'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default ScaleOutlinedIcon
