import React, { useState } from 'react'
import { IconButton, Stack } from '@mui/material'
import { EditIcon, LinkIcon, TrashIcon } from '../../../../components/Icons'
import {
  DeleteConfirmationDialog,
  EditChatTitleDialog,
} from '../../../../components/shared/Dialogs'
import { useSidebarContext } from '../../../../contexts'
import { toast } from 'react-toastify'
import { useCopyToClipboard } from '../../../../hooks'
import baseUrl, { domain } from '../../../../config/baseUrl'

interface Props {
  historyId: any
  threadId: any
  title: any
}

const Actions = ({ historyId, threadId, title }: Props) => {
  const [openDialog, setOpenDialog] = useState<{ editTitleDialog: boolean; deleteDialog: boolean }>(
    {
      editTitleDialog: false,
      deleteDialog: false,
    },
  )

  const userId = localStorage.getItem('userId')

  const handleClick = (name: string) => (_event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [name]: true,
    }))
  }

  const handleClose = (name: string) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [name]: false,
    }))
  }

  const [copyToClipboard] = useCopyToClipboard()
  const url = `${domain}/ShareChat/${threadId as string}`
  const handleShareClick = async () => {
    copyToClipboard(url)

    return false
  }

  const shareLink = async () => {
    try {
      const shareLinkRepsonse = await fetch(`${baseUrl}/api/sharechat/storesharechatnew`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserId: userId,
          ThreadId: threadId,
        }),
      })

      const shareLinkResponseData = await shareLinkRepsonse.json()

      if (shareLinkRepsonse.ok) {
        toast.success(shareLinkResponseData.message)
        sessionStorage.setItem('linkId', shareLinkResponseData.data.id)
      } else {
        toast.error(shareLinkResponseData.message)
      }
    } catch (error: any) {
      // console.log('Error in shareLink api : ', error.message)
      toast.error(error?.message)
    }
  }

  return (
    <>
      <Stack direction='row' spacing={1}>
        <IconButton sx={{ p: 0, color: 'common.gray' }} onClick={handleClick('editTitleDialog')}>
          <EditIcon />
        </IconButton>
        <IconButton
          sx={{ p: 0, color: 'common.gray' }}
          onClick={() => {
            shareLink()
            handleShareClick()
          }}
        >
          <LinkIcon strokeWidth='1.5' />
        </IconButton>
        <IconButton sx={{ p: 0, color: 'common.gray' }} onClick={handleClick('deleteDialog')}>
          <TrashIcon />
        </IconButton>
      </Stack>
      <EditChatTitleDialog
        open={openDialog?.editTitleDialog}
        onClose={() => {
          handleClose('editTitleDialog')
        }}
        historyId={historyId}
        title={title}
      />
      <DeleteConfirmationDialog
        open={openDialog?.deleteDialog}
        title='Are you sure, you want to Delete this Chat ?'
        onConfirm={() => {
          handleClose('deleteDialog')
        }}
        onClose={() => {
          handleClose('deleteDialog')
        }}
        threadIdToDelete={threadId}
      />
    </>
  )
}

export default Actions
