import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const GridIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 25 24' {...props}>
    <path
      d='M10.5 3H3.5V12H10.5V3Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.5 3H14.5V8H21.5V3Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.5 12H14.5V21H21.5V12Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.5 16H3.5V21H10.5V16Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default GridIcon
