import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const LandmarkIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M3.66675 22.5H21.6667'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.66675 18.5V11.5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6667 18.5V11.5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6667 18.5V11.5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.6667 18.5V11.5'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.6667 2.5L20.6667 7.5H4.66675L12.6667 2.5Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default LandmarkIcon
