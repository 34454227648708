import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
})

const MoreHorizontalIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 20' {...props}>
    <path
      d='M10.0003 10.8334C10.4606 10.8334 10.8337 10.4603 10.8337 10.0001C10.8337 9.53984 10.4606 9.16675 10.0003 9.16675C9.54009 9.16675 9.16699 9.53984 9.16699 10.0001C9.16699 10.4603 9.54009 10.8334 10.0003 10.8334Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.16634 10.8334C4.62658 10.8334 4.99967 10.4603 4.99967 10.0001C4.99967 9.53984 4.62658 9.16675 4.16634 9.16675C3.7061 9.16675 3.33301 9.53984 3.33301 10.0001C3.33301 10.4603 3.7061 10.8334 4.16634 10.8334Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default MoreHorizontalIcon
