import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import { GoogleOAuthProvider } from '@react-oauth/google'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    {/* <GoogleOAuthProvider clientId='1003317314253-tlqief0qsk5psonh7s85t4corsgcakst.apps.googleusercontent.com'> */}
    <GoogleOAuthProvider clientId='539734603559-g6v48cugl0ps2qpbugs50s4srhoa0rdu.apps.googleusercontent.com'>
      <App />
    </GoogleOAuthProvider>
  </>,
)
