import React, { useEffect } from 'react'
import { useSidebarContext } from '../../../../../contexts'
import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface Props {
  message: any
}
interface JsonData {
  result: string
}

interface markDownData {
  markdown: string
}
const Message = ({ message }: Props) => {
  // alert('inside Message from src/pages/ChatBoat/MainContent/Messages/Message/index.tsx')
  const { allHistory } = useSidebarContext()
  const { setAnswer, messages, setIsLoading, simplifyText } = useSidebarContext()

  // console.log('allHistory', allHistory)
  // setIsLoading(false)

  // const renderFormattedText = (text: string) => {
  // }

  // const MarkdownRenderer = ({ markdown }: markDownData) => {
  //   setIsLoading(false)
  //   return (
  //     <Box
  //       style={{
  //         width: '100%',
  //         overflow: 'auto',
  //       }}
  //     >
  //       <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
  //     </Box>
  //   )
  // }

  const MarkdownRenderer = (props: any) => {
    /* eslint-disable react/prop-types */
    const { textToDisplay } = props
    // console.log('355 textToDisplay', textToDisplay)
    // console.log('message', message)

    return (
      <Box
        style={{
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
          textAlign: 'justify',
          padding: '10px',
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{textToDisplay}</ReactMarkdown>
      </Box>
    )
  }

  // return <div>{renderFormattedText(message)}</div>
  return <MarkdownRenderer textToDisplay={message} />
}

export default Message
