import React from 'react'
import { Box, Grid, Stack, Typography, alpha, useTheme } from '@mui/material'
import { EmailIcon } from '../../../components/Icons'
import TextField from '../../../components/shared/TextField'
import SubscriptionDetails from './SubscriptionDetails'
import ChangePassword from './ChangePassword'

interface Props {
  userDetails: any,

}

const AccountInfo = ({ userDetails }: Props) => {
  const { palette } = useTheme()

  const { Email } = userDetails || {}



  return (
    <Stack spacing={2}>
      <Box
        display='flex'
        alignItems='center'
        bgcolor={alpha(palette.primary.main, 0.1)}
        borderRadius={1}
        height={33}
        px={1.5}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          mr={1.5}
          color='text.secondary'
        >
          <EmailIcon sx={{ width: 20, height: 20 }} />
        </Box>
        <Typography variant='subtitle2' color='text.secondary'>
          Account Info.
        </Typography>
      </Box>
      <Grid container px={1.5} gap={2.5}>
        <Grid item xs={12} md={7}>
          <TextField label='Email' name='email' value={Email} disabled />
        </Grid>
        <ChangePassword />
        <SubscriptionDetails />
      </Grid>
    </Stack>
  )
}

export default AccountInfo
