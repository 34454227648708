import React, { useContext, createContext, useState } from 'react'

interface FilteredContextValue {
  selectedCategory: string
  selectedCategories: string[]
  selectedVariableCategories: string[]
  selectedJudgesCategories: string[]
  selectedCourtsCategories: string[]
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedVariableCategories: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedJudgesCategories: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedCourtsCategories: React.Dispatch<React.SetStateAction<string[]>>
  filterCategoriesList: string[] // Specify the correct type here
  setFilterCategoriesList: React.Dispatch<React.SetStateAction<string[]>>
}

interface Props {
  children: React.ReactNode
}

const FilteredContext = createContext<FilteredContextValue | undefined>(undefined)

export const useFilteredContext = () => {
  const filteredContext = useContext(FilteredContext)
  if (filteredContext === undefined) {
    throw new Error('useFilteredContext must be inside a FilteredProvider')
  }
  return filteredContext
}

export const FilteredProvider = ({ children }: Props) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedVariableCategories, setSelectedVariableCategories] = useState<string[]>([])
  const [selectedJudgesCategories, setSelectedJudgesCategories] = useState<string[]>([])
  const [selectedCourtsCategories, setSelectedCourtsCategories] = useState<string[]>([])
  const [filterCategoriesList, setFilterCategoriesList] = useState<string[]>([])

  return (
    <FilteredContext.Provider
      value={{
        selectedCategory,
        selectedCategories,
        selectedVariableCategories,
        selectedJudgesCategories,
        selectedCourtsCategories,
        setSelectedCategory,
        setSelectedCategories,
        setSelectedVariableCategories,
        setSelectedJudgesCategories,
        setSelectedCourtsCategories,
        filterCategoriesList,
        setFilterCategoriesList,
      }}
    >
      {children}
    </FilteredContext.Provider>
  )
}

export default FilteredContext
