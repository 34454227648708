import React from 'react'
import { Link, createBrowserRouter } from 'react-router-dom'

import AuthLayout from '../pages/Auth'
// import AuthLogin from '../pages/Auth/Login'
import LoginIndex from '../pages/Auth/Login'
import AuthRegister from '../pages/Auth/Register'
import AuthForgotPassword from '../pages/Auth/ForgotPassword'
// import AuthEmailVerification from '../pages/Auth/EmailVerification'
// import HomeElement from './HomeElement'
import Setting from '../pages/Setting'
import FAQLayout from '../pages/Faq'
// import Questions from '../pages/Faq/Questions'
import Question from '../pages/Faq/Question'
import ContactUs from '../pages/ContactUs'
import ErrorBoundary from '../components/ErrorBoundary'
import MainContent from '../pages/ChatBoat/MainContent'
import SideBarLayout from '../Layout/SidebarLayout'
import HomeLayout from '../Layout/HomeLayout'
import HomeIndex from '../pages/Home'
import ChatBoat from '../pages/ChatBoat'
import NewChat from '../pages/NewChat/NewChat'
import Editor from '../pages/ChatBoat/MainContent/editor/Editor'
import NewChatQuestion from '../pages/NewChat/NewChatQuestion'
import ManyCategories from '../pages/ManyCategories'
// import NewChat from '../pages/NewChat/NewChat'
import ResetPassword from '../pages/Auth/ResetPassword'
import ChangePasswordDialogWithoutCurrentPassword from '../components/shared/Dialogs/ResetPassword'
import ShareChat from '../pages/ShareChat/ShareChat'
import SharelinkLayout from '../Layout/SharelinkLayout'
import SharechatIndex from '../pages/ShareChat'
import DownloadButtonDialog from '../components/shared/Dialogs/DownloadData'
import PaymentSuccessfulDialog from '../components/shared/Dialogs/PaymentSuccessfull'
import PrivacyAndTerms from '../pages/Auth/PrivacyAndTerms/index'
import { SidebarProvider } from '../contexts/Old_SidebarContext'
import { SidebarProvider as SProvider } from '../contexts/SidebarContext'
import { AuthProvider } from '../contexts/AuthContext'
import Verifier from '../pages/NewChat/Verifier'

const AppRoutes = createBrowserRouter([
  {
    element: (
      <AuthProvider>
        <SidebarProvider>
          <SProvider>
            <HomeLayout />
          </SProvider>
        </SidebarProvider>
      </AuthProvider>
    ), // Define a HomeLayout for the root path
    errorElement: <ErrorBoundary />,

    children: [
      {
        path: '/',
        element: (
          <SidebarProvider>
            <HomeIndex />
          </SidebarProvider>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        element: <AuthLayout />, // Under /auth, use AuthLayout
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: '/auth/login',
            element: <LoginIndex />,
          },
          {
            path: '/auth/register',
            element: <AuthRegister />,
          },
          {
            path: '/auth/privacy-terms',
            element: <PrivacyAndTerms />,
          },
          {
            path: '/auth/forgot-password',
            element: <AuthForgotPassword />,
          },
          {
            path: '/auth/reset-password-link/:email',
            element: <ResetPassword />,
          },
          {
            path: '/auth/reset-password',
            element: <ChangePasswordDialogWithoutCurrentPassword />,
          },
          {
            path: '/auth/download-file',
            element: <DownloadButtonDialog />,
          },
        ],
      },
      {
        element: (
          <SidebarProvider>
            <SideBarLayout />
          </SidebarProvider>
        ), // Use SidebarLayout for /sidebar and its children
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: '/NewChatQuestion',
            element: <NewChatQuestion />,
          },
          {
            path: '/NewChatQuestion/:question',
            element: <NewChatQuestion />,
          },
          {
            path: '/many-categories/:category',
            element: <ManyCategories />,
          },
          {
            path: '/MainContent/:id',
            element: <MainContent />,
          },
          {
            path: '/ShareChat/:id',
            element: <ShareChat />,
          },
          {
            path: '/PaymentSuccessfull',
            element: <PaymentSuccessfulDialog />,
          },
          {
            path: '/NewChat/:id',
            element: <NewChat />,
          },
          {
            path: '/NewChat',
            element: <NewChat />,
          },
        ],
      },
    ],
  },
  {
    path: '/editor',
    element: (
      <SidebarProvider>
        <Editor />
      </SidebarProvider>
    ),
  },
  {
    path: '/editor/:id',
    element: (
      <SidebarProvider>
        <Editor />
      </SidebarProvider>
    ),
  },
  {
    path: '/verifier',
    element: (
      <SidebarProvider>
        <Verifier />
      </SidebarProvider>
    ),
  },
  {
    path: '/verifier/:id',
    element: (
      <SidebarProvider>
        <Verifier />
      </SidebarProvider>
    ),
  },
  {
    element: <SharelinkLayout />,
    errorElement: <ErrorBoundary />,

    children: [
      {
        path: '/ShareChat/:id',
        element: <SharechatIndex />,
      },
    ],
  },
  {
    path: 'settings',
    element: <Setting />,
    handle: {
      crumb: () => <Link to='/settings'>Setting</Link>,
    },
  },
  {
    path: 'contact-us',
    element: <ContactUs />,
  },
  {
    element: <FAQLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/faq/need-help-with-a-billing-issue',
        element: <Question />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorBoundary />,
  },
])

export default AppRoutes

