import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
  width: 20,
  height: 20,
})

const TrashIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 21' {...props}>
    <path
      d='M2.5 5.5H17.5'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.8337 5.5V17.1667C15.8337 18 15.0003 18.8333 14.167 18.8333H5.83366C5.00033 18.8333 4.16699 18 4.16699 17.1667V5.5'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.66699 5.50008V3.83341C6.66699 3.00008 7.50033 2.16675 8.33366 2.16675H11.667C12.5003 2.16675 13.3337 3.00008 13.3337 3.83341V5.50008'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default TrashIcon
