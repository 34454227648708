import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'

const ScaleIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 24 24' {...props}>
    <path
      d='M16 16.5L19 8.5L22 16.5C21.13 17.15 20.08 17.5 19 17.5C17.92 17.5 16.87 17.15 16 16.5Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 16.5L5 8.5L8 16.5C7.13 17.15 6.08 17.5 5 17.5C3.92 17.5 2.87 17.15 2 16.5Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7 21.5H17' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12 3.5V21.5' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M3 7.5H5C7 7.5 10 6.5 12 5.5C14 6.5 17 7.5 19 7.5H21'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export default ScaleIcon
