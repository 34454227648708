import React, { useState } from 'react'
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

import RectangleImage from '../../../assets/images/rectangle.jpg'
import ContactForm from './ContactForm'
import { ThankYouDialog } from '../../../components/shared/Dialogs'

interface formData { }

const ContactSection = () => {
  const [successfullyDialog, setSuccessfullyDialog] = useState<boolean>(false)
  const theme = useTheme()
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))
  const mdMatched = useMediaQuery(theme.breakpoints.down('md'))

  const handleSubmit = (values: formData) => {
    setSuccessfullyDialog(true)
  }

  const handleClose = () => {
    setSuccessfullyDialog(false)
  }

  return (
    <Box id='contact-us' component='section' bgcolor='common.white' p={smMatched ? 3 : 7.5}>
      <Container maxWidth={mdMatched ? false : 'xl'} sx={{ px: { xs: 0, sm: 5, md: 7.5 } }}  >
        <Grid container spacing={7.5}>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <img src={RectangleImage} alt='contact-us' style={{ maxWidth: '95vw' }} />
              <Box
                sx={{
                  position: 'absolute',
                  pr: { xs: 0, sm: 3.5 },
                  py: 5,
                  maxWidth: 412,
                }}
              >
                <Typography variant='h2' color='white'>
                  Have a Doubt? We would love to help you !!
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContactForm setSuccessfullyDialog={setSuccessfullyDialog} />
          </Grid>
        </Grid>
      </Container>
      <ThankYouDialog open={successfullyDialog} onClose={handleClose} />
    </Box>
  )
}

export default ContactSection
