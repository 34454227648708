import React, { useEffect } from 'react'
import { IconButton, type IconButtonProps, styled, Tooltip } from '@mui/material'
import { CopyIcon } from '../../../../../components/Icons'
import { useCopyToClipboard } from '../../../../../hooks'
import { useSidebarContext } from '../../../../../contexts'
import { toast } from 'react-toastify'

interface Props {
  message: string
}

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  p: 0,
  width: 24,
  height: 24,
  color: theme.palette.background.border,
  '&:hover': {
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
  },
}))

const CopyButton = ({ message }: Props) => {
  const { simplifyText } = useSidebarContext()
  const [copyToClipboard, isCopied]: any = useCopyToClipboard()

  useEffect(() => {
    if (isCopied) {
      toast.success('Copied')
    }
  }, [isCopied])

  const handleClick = async () => {
    copyToClipboard(simplifyText(message))
    return false
  }

  return (
    <Tooltip title='Copy' placement='top'>
      <StyledIconButton onClick={handleClick}>
        <CopyIcon />
      </StyledIconButton>
    </Tooltip>
  )
}

export default CopyButton
