import React, { useEffect } from 'react'
import { Grid, Box, Typography } from '@mui/material'
import { AppLogoIcon } from '../../../components/Icons'
import ForgotPasswordForm from './ForgotPasswordForm'
import SquareLogo from '../../../assets/images/Lawtech icon square.png'

const ForgotPasswordIndex = () => {
  useEffect(() => {
    document.title = 'Forgot Password - LawTech'
  }, [])

  return (
    <Grid item xs={12} md={7} sx={{ overflowY: 'auto', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            maxWidth: 560,
            minHeight: 419,
            gap: 4,
            ml: { xs: 0, sm: 10.5 },
            p: { xs: 3, sm: 0 }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',

              width: '100%',
            }}
          >
            {/* <AppLogoIcon sx={{ width: 91, height: 63 }} /> */}
            <img src={SquareLogo} alt='' height='70px' />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',

              }}
            >
              <Typography variant='h3'>Forgot Password?</Typography>
              <Typography variant='body1'>
                Please enter you registered Email address to receive a verification code
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <ForgotPasswordForm />
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default ForgotPasswordIndex
