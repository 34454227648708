import React, { useEffect } from 'react'
import { IconButton, type TooltipProps, styled, useMediaQuery, useTheme } from '@mui/material'
import { SidebarIcon } from '../../../../components/Icons'
import { useSidebarContext } from '../../../../contexts'
import Tooltip from '../../../../components/shared/Tooltip'



const StyledSidebarIcon = styled(SidebarIcon, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  transform: open ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
  transition: theme.transitions.create('transform'),
  left: '100px',
}))

const Sidebar = () => {
  const { sidebarOpen, setSidebar } = useSidebarContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))


  const handleClick = () => {
    setSidebar(!sidebarOpen)
  }

  return (
    <Tooltip title={sidebarOpen ? 'Hide sidebar' : 'Show sidebar'} placement='right'>
      <IconButton
        disableRipple
        sx={({ palette }) => ({
          zIndex: 1250,
          // mt: 12,
          width: 32,
          height: 32,
          p: 0,
          borderRadius: 1,
          border: '1px solid',
          borderColor: palette.common.gray3,
          backgroundColor: palette.common.white,
          color: palette.common.gray,
        })}
        onClick={handleClick}
      >
        <StyledSidebarIcon open={sidebarOpen} />
      </IconButton>
    </Tooltip >
  )
}

export default Sidebar
