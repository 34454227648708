import React, { useEffect } from 'react'
import LawTechLogo from '../../../../assets/images/Lawtech.png'
import LeftDrawer from './LeftDrawer'
import RightDrawer from './RightDrawer'
import { SidebarProvider } from '../../../../contexts/Old_SidebarContext'
import { Grid, Button, Box, Typography, } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

function Editor() {

  useEffect(() => {
    document.title = 'Editor - LawTech'
  }, [])

  const navigate = useNavigate()

  const queryParams = parseQueryString()
  // console.log('queryParams', queryParams)

  function parseQueryString() {
    const queryString = window.location.search.substring(1)
    // console.log('queryString', queryString)
    const pairs = queryString.split('&')
    // console.log('pairs', decodeURIComponent(pairs))
    const parsed = {}

    for (let i = 0; i < pairs.length; i++) {
      const [key, value] = pairs[i].split('=')
      parsed[decodeURIComponent(key)] = decodeURIComponent(value)
    }

    // console.log('parsed', parsed)
    return parsed
  }

  const objectFromQuery = queryParams
  // console.log('objectFromQuery', objectFromQuery)

  return (
    <>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#e5e5e5' }}>
        <img
          src={LawTechLogo}
          alt='logo'
          style={{
            width: '48px',
            height: 'auto',
            marginTop: '10px',
            marginLeft: '10px',
          }}
        />

        <Typography
          variant='h4'
          textAlign={'center'}
          style={{
            fontWeight: '700',
            color: '#093F6B',
          }}
        >
          Edit Response
        </Typography>

        <Button
          style={{
            color: '#777',
            // backgroundColor: '#da0404',
            zIndex: '10000',
            padding: '1px 5px',
            width: '100px',
          }}
          onClick={() =>
            navigate(objectFromQuery?.hId ? `/NewChat/${objectFromQuery?.hId}` : '/NewChat')
          }
        >
          <ArrowBackIcon /> Back
        </Button>
      </Box>

      <SidebarProvider position='relative'>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '100vh',
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              <LeftDrawer />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RightDrawer
                ans={objectFromQuery?.ans}
                qs={objectFromQuery?.qs}
                hId={objectFromQuery?.hId}
              />
            </Grid>
          </Grid>
        </Box>
      </SidebarProvider>
    </>
  )
  // return (
  //   <Box>
  //     <LeftDrawer />
  //     {/* <Button
  //       style={{
  //         position: 'absolute',
  //         zIndex: '10000',
  //         top: '.9rem',
  //         left: '50%',
  //         color: '#ffdb49',
  //         backgroundColor: '#da0404',
  //         width: '5rem',
  //         padding: '.25rem',
  //         transform: 'translate(-50%)',
  //       }}
  //     >
  //       Back
  //     </Button> */}
  //     <RightDrawer />
  //   </Box>
  // )
}

export default Editor
