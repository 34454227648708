import React, { useId, useState } from 'react'
import { Link as RouteLink } from 'react-router-dom'
import { Box, Button, Typography, Link, Stack } from '@mui/material'
import Category from './Category'
import { BankNoteIcon, LandmarkIcon, ScaleIcon } from '../../../components/Icons'
import baseUrl from '../../../config/baseUrl'

interface Props {
  setDialog: any
}

const Categories = ({ setDialog }: Props) => {
  const userId = localStorage.getItem('userId')
  const [selectedCategory, setSelectedCategory] = useState<string>('')

  const handleClick = (selectedValue: string) => {
    if (selectedCategory !== selectedValue) {
      setSelectedCategory(selectedValue)
    } else {
      setSelectedCategory('')
    }
  }

  const dataSubmit = async () => {
    if (userId) {
      try {
        // Make an API call here using the formData
        const response = await fetch(`${baseUrl}/api/auths/registerstep2`, {
          method: 'POST',
          headers: {
            Origin: `${baseUrl}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userid: userId,
            Category: selectedCategory,
          }),
        })

        const data = await response.json()

        if (data.status === true) {
          // Handle successful response
          setDialog(true)
        }
      } catch (error) {
        console.error('An error occurred while submitting the form data', error)
      }
    }
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Stack
          direction='row'
          spacing={{ xs: 1, sm: 2, md: 4 }}
          useFlexGap
          flexWrap='wrap'
          sx={{
            alignItems: { xs: 'center', md: 'flex-start' },
            justifyContent: { xs: 'center', md: 'flex-start' },
          }}
        >
          <Category
            name='Civil Cases'
            selected={selectedCategory}
            CategoryIcon={<LandmarkIcon />}
            onClick={handleClick}
          />
          <Category
            name='Criminal Cases'
            selected={selectedCategory}
            CategoryIcon={<ScaleIcon />}
            onClick={handleClick}
          />
          <Category
            name='Bankruptcy Cases'
            selected={selectedCategory}
            CategoryIcon={<BankNoteIcon />}
            onClick={handleClick}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex- start' },
          width: '100%',
          gap: 2.5,
        }}
      >
        <Button
          disabled={!selectedCategory}
          variant='contained'
          onClick={() => {
            dataSubmit()
          }}
        >
          Sign Up
        </Button>
        <Typography variant='subtitle1'>
          Already a member?
          <Link
            component={RouteLink}
            variant='subtitle2'
            to='/auth/login'
            underline='hover'
            color='textSecondary'
            ml={0.5}
          >
            Login
          </Link>
        </Typography>
      </Box >
    </>
  )
}

export default Categories
