import React, { useContext, useEffect, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import type { AlertColor } from '@mui/material/Alert'
import { Outlet, useNavigate } from 'react-router-dom'
import SContext from '../contexts/SidebarContext'

import baseUrl from '../config/baseUrl'

interface SContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  // Add other properties as needed
}

function HomeLayout() {
  const navigate = useNavigate()
  const { isLoggedIn, setIsLoggedIn } = useContext<SContextType>(SContext);
  const [lastUrl, setLastUrl] = useState('')
  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)

  const token = sessionStorage.getItem('token')

  useEffect(() => {
    setLastUrl(localStorage?.getItem('urlAtLeaving') ?? '')

    const fetchIpAndStore = async () => {
      try {
        // Fetch the IP address
        const ipResponse = await fetch('https://api.ipify.org?format=json')
        const ipData = await ipResponse.json()
        // console.log('ipData', ipData)
        const ip = ipData?.ip

        // Send the IP address to the backend
        await fetch(`${baseUrl}/api/admin/storeIpAddress`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipAddress: ip }),
        })
      } catch (error) {
        console.error('Error fetching/storing IP address:', error)
      }
    }
    fetchIpAndStore()

    const handleAfterUnload = (event: any) => {
      event.preventDefault();
      setIsLoggedIn(false);
    }

    const handleBeforeUnload = (event: any) => {
      event.preventDefault();

      setLastUrl(window.location.href);
      localStorage.setItem('urlAtLeaving', window.location.href);
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('afterUnload', handleAfterUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('afterUnload', handleAfterUnload);
    };
  }, [])


  useEffect(() => {
    if (lastUrl && lastUrl !== window.location.href) {
      if (window.location.href.includes(lastUrl)) {
        // Remove lastUrl from window.location.href
        const newString = window.location.href.replace(lastUrl, '');
        navigate(newString, { replace: true })
        localStorage.removeItem('urlAtLeaving')
      }
    }
  }, [lastUrl])


  useEffect(() => {
    if (window.location.href.includes('auth/login') && isLoggedIn) {
      navigate('/NewChat', { replace: true })
    }
  }, [window.location.href])


  // useEffect(() => {
  //   const storedUrlAtLeaving = localStorage.getItem('urlAtLeaving')
  //   console.log('storedUrlAtLeaving', storedUrlAtLeaving)
  //   console.log('window.location.href', window.location.href)
  //   if (storedUrlAtLeaving) {
  //     navigate(storedUrlAtLeaving)
  //     localStorage.removeItem('urlAtLeaving')
  //   }
  // }, [window.location.href])

  if (token === undefined) {
    // navigate('/')
    setSnackbarMessage('Your session has expired, please login again')
    setSnackbarSeverity('info')
    setSnackbarOpen(true)
    navigate('/auth/login', { replace: true })
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  return (
    <>
      <div>
        <Outlet />
      </div>
      {snackbarOpen && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            elevation={6}
            variant='filled'
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default HomeLayout
