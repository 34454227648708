import React, { useRef, useState } from 'react'
import baseUrl from '../../../../config/baseUrl'
import {
  Box,
  Alert,
  Snackbar,
  InputBase, TextField, type InputBaseProps,
  InputAdornment,
  Tooltip,
  IconButton,

} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import type { AlertColor } from '@mui/material/Alert'
import { ToastContainer, toast } from 'react-toastify'
import { styled } from '@mui/material/styles'
import { SearchIcon } from '../../../../components/Icons'
import { useSidebarContext } from '../../../../contexts'



const SearchInput = () => {
  const searchRef = useRef()
  const { userHistory, setUserHistory, searchValue, setSearchValue } = useSidebarContext()
  const [searchText, setSearchText] = useState<string>('') // State to store user input
  const userId = localStorage.getItem('userId')
  // const { searchText, setSearchText } = useSidebarContext()

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined)


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleClick = () => {

  }

  const handleChange = (e: any) => {
    setSearchText(e.target.value)
  }

  const handleEnterKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearchChange()
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (searchText.length === 0) {
        handleClearSearch()
      }
    }
  }

  const handleSearchChange = async () => {
    if (searchText?.length === 0) {
      setSnackbarMessage('Enter something to search!');
      setSnackbarSeverity('info');
      setSnackbarOpen(true)
    }
    if (searchText?.length !== 0) {
      setSearchValue(searchText)

      try {
        const res = await fetch(`${baseUrl ?? ''}/api/users/FindHistoryAll/${userId ?? ''}`);
        // console.log('handleSearchChange res', res)
        const data = await res.json();
        // console.log('handleSearchChange', data);
        if (data?.status) {
          setUserHistory(data?.data);
        }
        if (!res.ok) {
          setSnackbarMessage(data?.message);
          setSnackbarSeverity('info');
          setSnackbarOpen(true)
        }
      } catch (error: any) {
        // console.log(error);
        toast.error(error)
      }
    }
  }

  const handleClearSearch = async () => {
    setSearchText('')
    setSearchValue('')
    try {
      const res = await fetch(`${baseUrl ?? ''}/api/users/FindHistoryAll/${userId ?? ''}`);
      const data = await res.json();
      if (data?.status) {
        setUserHistory(data?.data);
      }
      if (!res.ok) {
        setSnackbarMessage(data?.message);
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(error as string);
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    }
  }

  return (
    <Box style={{ position: 'relative', margin: 0, padding: 0, boxSizing: 'border-box' }}>
      <ToastContainer />
      <TextField
        style={{ padding: 0 }}
        size='small'
        inputRef={searchRef}
        variant='outlined'
        placeholder='History search...'
        fullWidth
        value={searchText}
        onChange={handleChange}
        onKeyDown={handleEnterKeyPress}
        margin='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start' style={{ margin: 0, padding: 0, boxSizing: 'border-box' }}>
              <IconButton style={{ margin: 0, boxSizing: 'border-box' }} onClick={handleSearchChange}>
                <SearchIcon style={{ margin: 0, boxSizing: 'border-box' }} />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end' style={{ margin: 0, padding: 0, boxSizing: 'border-box' }}>
              <Tooltip title="Clear search" placement='right'>
                <IconButton style={{ margin: 0, boxSizing: 'border-box' }} onClick={handleClearSearch}>
                  <CloseIcon style={{ margin: 0, boxSizing: 'border-box', color: 'red' }} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      // value={searchQuery}
      // onChange={handleSearchChange}
      />

      {!open && <IconButton onClick={handleClick}>
        <SearchIcon />
      </IconButton>}

      {snackbarOpen && (
        <Snackbar style={{ position: 'absolute', top: 70, left: 0, width: '100%' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            elevation={6}
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}

    </Box>
  )
}

export default SearchInput
