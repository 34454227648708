import React from 'react'
import { SvgIcon, styled, type SvgIconProps } from '@mui/material'

const StyledSvgIcon = styled(SvgIcon)<SvgIconProps>({
  fill: 'none',
  stroke: 'currentcolor',
  width: 20,
  height: 20,
})

const MessageSquareIcon = (props: SvgIconProps) => (
  <StyledSvgIcon viewBox='0 0 20 21' {...props}>
    <path
      d='M17.5 13C17.5 13.442 17.3244 13.866 17.0118 14.1785C16.6993 14.4911 16.2754 14.6667 15.8333 14.6667H5.83333L2.5 18V4.66667C2.5 4.22464 2.67559 3.80072 2.98816 3.48816C3.30072 3.17559 3.72464 3 4.16667 3H15.8333C16.2754 3 16.6993 3.17559 17.0118 3.48816C17.3244 3.80072 17.5 4.22464 17.5 4.66667V13Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </StyledSvgIcon>
)

export default MessageSquareIcon
