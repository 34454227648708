import React from 'react'
import { Outlet } from 'react-router-dom'
import { Grid, Box, Paper, Typography } from '@mui/material'
import { PanaIcon } from '../../components/Icons'
import { useAuthContext } from '../../contexts'
const AuthLayout = () => {
  const { isAuthenticated } = useAuthContext()
  return (
    <Box
      sx={{ height: { xs: 'auto', md: '100dvh' }, overflow: 'hidden', bgcolor: 'common.white', position: 'relative', paddingBottom: { xs: 10, md: 0 }, justifyContent: 'center', }}
    >
      <Grid container sx={{ height: { xs: 'auto', md: 'calc(100% - 40px)' } }}>
        <Grid item xs={12} md={5}>
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 'none',
              bgcolor: 'background.main',
              height: '100%',
            }}
          >
            <PanaIcon sx={{ width: '80%', height: '80%' }} />
          </Paper>
        </Grid>
        <Outlet />
      </Grid>
      <Paper
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'transparent',
          px: 2,
        }}
      >
        {/* <Typography
          variant='subtitle2'
          sx={{ color: 'text.primary', position: 'fixed', bottom: '1px' }}
        >
          © {new Date().getFullYear()} LawTech. All rights reserved
        </Typography> */}
      </Paper>
    </Box>
  )
}

export default AuthLayout


