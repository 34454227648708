import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Breadcrumbs as DefaultBreadcrumbs, Link as MuiLink, Typography } from '@mui/material'
import { HomeIcon, ChevronRightIcon } from '../../Icons'

const Breadcrumbs = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/NewChat')
  }
  return (
    <DefaultBreadcrumbs
      component='div'
      separator={
        <ChevronRightIcon
          sx={{
            width: 20,
            height: 20,
            color: 'background.border',
            fill: 'none',
            stroke: 'currentcolor',
          }}
        />
      }
    >
      <MuiLink
        component={Link}
        to='/'
        underline='none'
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'background.border',
          '&:hover': {
            color: 'common.gray',
          },
        }}
      >
        <HomeIcon
          sx={{ width: 20, height: 20, fill: 'none', stroke: 'currentcolor' }}
          onClick={handleClick}
        />
      </MuiLink>
      <MuiLink
        component={Link}
        to='/settings'
        underline='none'
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'common.gray',
        }}
      >
        <Typography variant='subtitle2'>Settings</Typography>
      </MuiLink>
    </DefaultBreadcrumbs>
  )
}

export default Breadcrumbs
